/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    width: 100%;
  }
  .root .header {
    background-color: var(--ggl-panel-bg-alt);
  }
  .row,
  .td,
  .th {
    height: 3rem;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    vertical-align: middle;
  }
  .th {
    font-weight: 600;
  }
  .sortable {
    position: relative;
    cursor: pointer;
  }
  .title {
    display: inline-block;
  }
  .sortIcons {
    display: inline-block;
    width: 1rem;
    position: absolute;
    height: 3rem;
    padding: 0.5rem;
  }
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
  .center {
    text-align: center;
  }
  .ascIcon,
  .descIcon {
    width: 1rem;
    height: 1rem;
    position: relative;
    opacity: 0.35;
  }
  .active {
    opacity: 1;
  }
  .ascIcon {
    top: 0.1875rem;
  }
  .descIcon {
    bottom: 0.1875rem;
  }
  .iconLayout {
    display: flex;
    flex-direction: column;
    height: 3rem;
  }
}