@layer components {
  .root {
    padding: 0.25rem;
    width: 2.5rem;
    border-radius: 1rem;
    cursor: pointer;
    height: 1.25rem;
    border: solid thin var(--ggl-control-border);
    position: relative;
    background-color: transparent;
    padding: 0.25rem;
    margin: 0;
    vertical-align: middle;
  }
  .root::before {
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    content: " ";
    position: relative;
    display: block;
    top: -0.05rem;
  }
  .root.on {
    border-color: var(--ggl-item-primary-bg);
    background-color: var(--ggl-item-primary-bg);
  }
  .root.on::before {
    background-color: var(--ggl-item-primary-text);
    left: 1.05rem;
  }
  .root.off::before {
    background-color: var(--ggl-control-border);
  }
  .disabled {
    border-color: var(--ggl-border-disabled) !important;
    color: var(--ggl-text-disabled);
  }
  .disabled.on {
    background-color: var(--ggl-border-disabled);
  }
  .disabled.off::before {
    background-color: var(--ggl-border-disabled);
  }
}