/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .imageField {
    --ggl-img-container-bg: black;
  }
  .imageField > div {
    padding: 1rem;
  }
  .imageField .controls {
    display: flex;
  }
  .imageField .container {
    display: flex;
    width: 100vw;
  }
  .imageField .container.medium {
    place-content: center space-between;
  }
  .imageField .container.medium figure {
    height: 5.5rem;
  }
  .imageField .container.medium .controls {
    row-gap: 1rem;
    flex-direction: column-reverse;
    justify-content: space-around;
  }
  .imageField .container.large {
    place-content: space-between;
    flex-direction: column;
    row-gap: 1rem;
  }
  .imageField .container.large figure {
    height: 13rem;
  }
  .imageField .container.large .controls {
    column-gap: 1rem;
    flex-direction: row-reverse;
  }
  .imageField .container.large .controls button {
    flex-basis: calc(50% - 0.5rem);
    flex-shrink: 0;
  }
  .imageField .imgBox {
    height: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ggl-control-bg);
    border-radius: var(--ggl-border-radius-input);
    border: thin solid var(--ggl-control-border-light);
    cursor: pointer;
    flex-direction: column;
    gap: 0.5rem;
    user-select: none;
  }
  .imageField .imgBox.hasImage {
    background-color: var(--ggl-img-container-bg);
    border: none;
  }
  .imageField .imgBox p {
    text-align: center;
    color: var(--ggl-control-border);
  }
  .imageField .image {
    object-fit: contain;
    height: inherit;
  }
  .readOnlyLabel {
    display: flex;
    align-items: center;
  }
  .readOnlyLabel .text {
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    width: 100%;
  }
  .popup {
    min-width: 50vw;
    min-height: 50vh;
  }
  .viewImg {
    background-color: var(--ggl-img-container-bg);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--ggl-border-radius-input);
  }
  .missingImg {
    height: 2rem;
    width: 3rem;
    fill: var(--ggl-control-border);
  }
}