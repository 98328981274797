/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    position: fixed;
    top: 0;
    right: 0;
    max-height: 100%;
    box-shadow: var(--ggl-drop-shadow-high);
    border-radius: 0.25rem;
    overflow: hidden;
    width: 30rem;
    animation-duration: 0.3s;
    animation-timing-function: ease-in;
    border: solid thin var(--ggl-panel-border);
  }
  .backdrop {
    z-index: initial;
  }
  .panel {
    width: 30rem;
    background-color: var(--ggl-form-bg);
  }
  .drawer {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .drawer .panel {
    height: 100vh;
    height: 100dvh;
  }
  .title {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    height: 3.5rem;
    background-color: var(--ggl-panel-bg);
    padding: 1rem;
    border-bottom: solid thin var(--ggl-panel-border);
  }
  .titleText {
    flex-grow: 1;
  }
  .titleText span {
    vertical-align: baseline;
  }
  .icon {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
    fill: var(--ggl-item-primary-text);
  }
  .body {
    height: calc(100% - 3.5rem);
    overflow-y: auto;
    padding: 1.5rem;
  }
  .body.hasActions {
    height: calc(100% - 7rem);
  }
  .buttonBarContainer {
    padding: 0.75rem;
    display: flex;
    border-top: solid thin var(--ggl-panel-border);
  }
  .mounting {
    width: 30rem;
    animation-name: mountPanel;
  }
  .unmounting {
    animation-name: unMountPanel;
    width: 0;
  }
  .attention .title {
    background-color: var(--ggl-item-attention-bg);
  }
  .destructive .title {
    background-color: var(--ggl-item-destructive-bg);
  }
  .primary .title {
    background-color: var(--ggl-item-primary-bg);
  }
  .success .title {
    background-color: var(--ggl-item-success-bg);
  }
  .attention .titleText,
  .destructive .titleText,
  .primary .titleText,
  .success .titleText {
    color: var(--ggl-item-primary-text);
  }
  .attention .closeButton svg,
  .destructive .closeButton svg,
  .primary .closeButton svg,
  .success .closeButton svg {
    fill: var(--ggl-item-primary-text) !important;
  }
  .attention .closeButton:hover,
  .destructive .closeButton:hover,
  .primary .closeButton:hover,
  .success .closeButton:hover {
    background: none !important;
  }
  .closeButton {
    background: none;
  }
  .base .closeButton:hover {
    background: var(--ggl-control-button-hover);
  }
  @keyframes mountPanel {
    0% {
      width: 0;
    }
    100% {
      width: 30rem;
    }
  }
  @keyframes unMountPanel {
    0% {
      width: 30rem;
    }
    100% {
      width: 0;
    }
  }
  .popup {
    width: 20.625rem;
    bottom: 1rem;
    right: 1rem;
    top: initial;
    animation-duration: 0.25s;
  }
  .popup aside {
    width: 20.625rem;
  }
  .mounted {
    max-height: calc(100vh - 2rem);
    height: fit-content;
  }
  .mountingPopUp {
    animation-name: mountPopUp;
  }
  .unmountingPopUp {
    animation-name: unMountPopUp;
    max-height: 0;
  }
  @keyframes mountPopUp {
    0% {
      max-height: 0;
    }
    100% {
      max-height: calc(100vh - 4rem);
      height: fit-content;
    }
  }
  @keyframes unMountPopUp {
    0% {
      max-height: 50rem;
    }
    100% {
      max-height: 0;
    }
  }
  @media only screen and (max-width: 860px) {
    .root {
      width: calc(100vw - 4rem);
      margin: auto;
      top: initial;
      left: 1rem;
      right: 1rem;
      border-radius: 0.25rem;
      max-height: calc(100vh - 4rem);
      height: 100%;
    }
    .root:not(.popup) {
      height: calc(100vh - 2rem);
    }
    .root aside {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .body.hasActions {
      max-height: calc(100vh - 11rem);
    }
    .panel {
      width: 100%;
    }
    .mounting {
      animation-name: mountPopUp;
    }
    .unmounting {
      max-height: 0;
      animation-name: unMountPopUp;
    }
    .backdrop {
      display: block;
      visibility: visible;
      animation-duration: 0.25s;
    }
    .backdrop :global {
      animation-name: fadeIn;
    }
    .backdropUnmount {
      animation-duration: 0.25s;
      visibility: hidden;
      opacity: 0;
    }
    .backdropUnmount :global {
      animation-name: fadeOut;
    }
  }
}
@media only screen and (min-width: 860px) and (max-width: 1366px) and (orientation: landscape) and (hover: none) {
  .drawer .panel {
    position: fixed;
    height: calc(100vh - 4rem);
    height: 100dvh;
  }
}