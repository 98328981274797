/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .userCard {
    width: 100%;
    padding: 0.5rem 1rem;
    --ggl-user-card-gap: 1rem;
    --ggl-user-card-check: 0.625rem;
    --ggl-user-card-expand: 0.3125rem;
  }
  .userCard.large {
    padding: 1rem;
    --ggl-user-card-check: 1.25rem;
    --ggl-user-card-expand: 1rem;
  }
  .check {
    position: relative;
    top: var(--ggl-user-card-check);
  }
  .expand {
    position: relative;
    top: var(--ggl-user-card-expand);
  }
  .name {
    flex-grow: 1;
  }
  .name h4 {
    font-size: 16px;
  }
  .top {
    width: 100%;
    display: flex;
    gap: var(--ggl-user-card-gap);
  }
  .dl {
    font-size: var(--ggl-font-size);
    line-height: 1.25rem;
  }
  .large .dl {
    display: grid;
    grid-template-columns: minmax(0, 15rem) minmax(0, 15rem);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
  }
  .large .dl .dtRow:first-child,
  .large .dl .dtRow:nth-child(odd) {
    border-right: solid thin var(--ggl-control-border-light);
  }
  .dtRow {
    display: flex;
    gap: 0.5rem;
    flex-basis: 50%;
  }
  .dt {
    min-width: 4rem;
    flex-shrink: 0;
  }
}