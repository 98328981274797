/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .options {
    height: fit-content;
    position: relative;
    flex-direction: row;
    display: flex;
    margin-left: -0.75rem;
    width: fit-content;
  }
  .options.skeleton {
    margin-left: 0;
    width: 10rem;
  }
  .readonly {
    border-color: var(--ggl-border-readonly);
  }
  .readonly:focus-within {
    border-color: var(--ggl-border-readonly-focus);
  }
  .label {
    margin-bottom: 0.5rem;
  }
  .column {
    display: block;
  }
  .skeleton {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
  }
  .skeleton :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .skeleton * {
    visibility: hidden !important;
  }
  .skeleton::after {
    display: none;
  }
  .disabled {
    color: var(--ggl-text-disabled);
    border-color: var(--ggl-border-disabled);
    background-color: var(--ggl-disabled-bg);
  }
  .labelDisabled {
    color: var(--ggl-text-disabled);
  }
}