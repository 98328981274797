/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .errorContainer {
    font-size: 12px;
    line-height: 1rem;
    height: 1rem;
  }
  .errorMessage {
    color: var(--ggl-item-destructive-bg);
    line-height: 1rem;
    margin: 0;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}