@layer components {
  .tabsContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding-left: 21.5rem;
    height: 100%;
    word-break: break-all;
  }
  .headersContainer {
    display: flex;
    flex-direction: row;
    background-color: var(--ggl-body-bg);
    border-bottom: thin solid var(--ggl-panel-border);
    justify-content: left;
    z-index: 2;
    position: fixed;
    width: calc(100% - 26.5rem);
    text-align: center;
  }
  .tabHeader,
  .tabHeaderActive {
    cursor: pointer;
    position: relative;
    outline: none;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.75rem 0;
    padding: 0 2rem;
    color: #888888;
  }
  .tabHeader:last-child,
  .tabHeaderActive:last-child {
    border-right: none;
  }
  .tabHeader:hover::before {
    content: "";
    position: absolute;
    bottom: -0.85rem;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: var(--ggl-control-border-light);
  }
  .tabHeaderActive::before {
    content: "";
    position: absolute;
    bottom: -0.85rem;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: var(--ggl-brand);
  }
  .contentContainer {
    flex-grow: 1;
    text-align: center;
    margin-top: 4rem;
  }
  .tabContent {
    padding: 1.25rem;
    width: 75%;
    height: 100%;
    display: none;
  }
  .tabContentActive {
    text-align: center;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
  }
  .icon {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
  }
  @media only screen and (max-width: 1200px) {
    .tabsContainer {
      width: 100%;
      margin-top: 7rem;
      padding-left: 1.5rem;
    }
    .headersContainer {
      margin-top: 0;
      width: 100%;
    }
  }
}