@layer components {
  .innerContainer {
    display: inline-block;
    touch-action: none;
    position: relative;
    overflow: hidden;
    max-width: 33rem;
    max-height: 35rem;
  }
  .image {
    cursor: crosshair;
    display: block;
    max-height: 50vh;
    max-width: 100vw;
    height: 100%;
    width: 100%;
    user-select: none;
  }
  .crop {
    border: 1px solid var(--ggl-link-color);
    box-shadow: 0 0 0 9999rem rgba(34, 34, 34, 0.5);
    cursor: move;
    display: block;
    position: absolute;
    z-index: 1;
  }
  .handle {
    background: var(--ggl-link-color);
    border: 1px solid var(--ggl-link-color);
    border-radius: 50%;
    height: 1rem;
    position: absolute;
    width: 1rem;
    z-index: 2;
  }
  .n {
    cursor: n-resize;
    left: calc(50% - 1rem / 2);
    top: calc(1rem / -2 - 1px);
  }
  .ne {
    cursor: ne-resize;
    top: calc(1rem / -2 - 1px);
    right: calc(1rem / -2 - 1px);
  }
  .e {
    cursor: e-resize;
    top: calc(50% - 1rem / 2);
    right: calc(1rem / -2 - 1px);
  }
  .se {
    cursor: se-resize;
    right: calc(1rem / -2 - 1px);
    bottom: calc(1rem / -2 - 1px);
  }
  .s {
    cursor: s-resize;
    left: calc(50% - 1rem / 2);
    bottom: calc(1rem / -2 - 1px);
  }
  .sw {
    cursor: sw-resize;
    bottom: calc(1rem / -2 - 1px);
    left: calc(1rem / -2 - 1px);
  }
  .w {
    cursor: w-resize;
    top: calc(50% - 1rem / 2);
    left: calc(1rem / -2 - 1px);
  }
  .nw {
    cursor: nw-resize;
    top: calc(1rem / -2 - 1px);
    left: calc(1rem / -2 - 1px);
  }
  @media (min-width: 768px) {
    .handle {
      height: 0.7rem;
      width: 0.7rem;
    }
    .n {
      left: calc(50% - 0.7rem / 2);
    }
    .e {
      top: calc(50% - 0.7rem / 2);
    }
    .s {
      left: calc(50% - 0.7rem / 2);
    }
    .w {
      top: calc(50% - 0.7rem / 2);
    }
    .n,
    .nw,
    .ne {
      top: calc(0.7rem / -2 - 1px);
    }
    .e,
    .ne,
    .se {
      right: calc(0.7rem / -2 - 1px);
    }
    .s,
    .se,
    .sw {
      bottom: calc(0.7rem / -2 - 1px);
    }
    .w,
    .sw,
    .nw {
      left: calc(0.7rem / -2 - 1px);
    }
  }
}