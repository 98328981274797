$brand: "ggl";
.nav {
  padding-top: 0.75rem;
  width: 2.5rem;
  background-color: var(--ggl-nav-bg);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 500ms;
  position: fixed;
  z-index: var(--nav-z-index);
  .closed {
    .label,
    .title {
      opacity: 0;
      transition: opacity 500ms;
    }
  }
}
.title{
  display: none;
  font-size: 0.875rem;
}
.navHeader{
  display: flex;
  justify-content: center;
}

.navHeader svg{
  margin-bottom: 1rem;
}

.closed {
  width: 3.5rem;
}

.items {
  margin: 0;
  padding: 0;
}

.item {
  height: 2.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;

  &:hover {
    background-color: var(--ggl-nav-hover);
  }
  &:hover .item_icon{
    fill: var(--ggl-header-text);
  }

  &_icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--ggl-nav);
  }
}

.active {
  background-color: var(--ggl-nav-active);
  .link {
    color: var(--ggl-header-text);
  }
  .item_icon {
    fill: var(--ggl-header-text);
  }
  &:hover{
    background-color: var(--ggl-nav-hover);
    
      .item_icon {
        fill: var(--ggl-header-text);
      }
  }
}

.link {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: var(--ggl-header-text);
}

.toggle {
  height: 2.5rem;
  width: 15.5rem;
  background-color: transparent;
  margin: 0.25rem;
  border: none;
  font-size: 1.125rem;
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  align-items: center;
  margin-top: auto;
  cursor: pointer;
  text-align: left;
  overflow: hidden;
  color: var(--ggl-header-text);
  padding-left: 0.65rem;
  &_icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--ggl-header-text);
  }
}

.label {
  display: block;
  white-space: nowrap;
}

.backdrop {
  opacity: 0;
  transition: opacity 0.5s;
  z-index: var(--nav-z-index) !important;
  // keep this rendered but out of view/interaction range
  // we need to do this so we can get the fade-in animation working
  // as swapping from invisible to visible resets the transition
  // rendering so far left won't generate a scroll bar
  left: -9999px !important;
}

@media only screen and (max-width: 860px) {
  .link{
    justify-content: flex-start;
    margin-left: 0.5rem;
  }
  .title{
    margin-left: 0.25rem;
    display: block;
  }
  .nav {
    width: 16rem;
    box-shadow: var(--ggl-drop-shadow-medium);
    transition: 750ms;
    z-index: var(--nav-z-index);
    &.closed {
      width: 16rem;
      left: -16.5rem;
    }
  }
  .backdrop.open {
    left: 0 !important; // move this back into interaction range
    opacity: 0.5;
  }
}

@media print {
  .nav {
    display: none;
  }
}
