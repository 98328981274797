@layer components {
  :root {
    --ggl-list-gutter: #f2f2f2;
    --ggl-list-item-selected: #e8f3ff;
  }
  :root[data-theme=dark] {
    --ggl-list-gutter: #1c1c1c;
    --ggl-list-item-selected: #0461bf;
  }
  .scrollBox {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .listBox {
    width: 100%;
    height: 100%;
    background: var(--ggl-list-gutter);
    display: flex;
    flex-direction: column;
    box-shadow: var(--ggl-drop-shadow-inset);
  }
  .listBoxItem {
    height: 3.5rem;
    flex-shrink: 0;
    width: 100%;
    border: none;
    background-color: var(--ggl-control-bg);
    border-bottom: thin solid var(--ggl-panel-border);
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }
  .listBoxItem:last-child {
    box-shadow: var(--ggl-drop-shadow-low);
  }
  .listBoxItem.isActive {
    color: var(--ggl-item-text);
    border: thin solid var(--ggl-item-text);
    background-color: var(--ggl-list-item-selected);
  }
  .listBoxItem:active {
    color: var(--ggl-item-active-text);
    border-color: var(--ggl-item-active-border);
    border: thin solid var(--ggl-item-active-border-tint);
    background-color: var(--ggl-item-active-bg);
  }
  .listBoxItem:hover {
    color: var(--ggl-item-text);
    border: thin solid var(--ggl-item-hover-bg);
    background-color: var(--ggl-item-hover-bg);
  }
}