/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .progressBar {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
  .icon {
    border: 1px solid var(--ggl-item-primary-bg);
    background-color: var(--ggl-control-bg);
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    place-content: center center;
    cursor: pointer;
    position: relative;
    font-weight: 600;
    color: var(--ggl-item-primary-bg);
  }
  .icon.current {
    background-color: var(--ggl-item-primary-bg);
    color: white;
  }
  .icon.current svg {
    background-color: var(--ggl-item-primary-bg);
    fill: var(--ggl-item-primary-text);
  }
  .icon svg {
    background-color: var(--ggl-item-primary-text);
    fill: var(--ggl-item-primary-bg);
    height: 1.25rem;
    width: 1.25rem;
  }
  .icon.disabled {
    background-color: var(--ggl-control-light-bg);
    border: 1px solid var(--ggl-text-faint);
    cursor: default;
    color: var(--ggl-text-faint);
  }
  .icon.disabled svg {
    background-color: var(--ggl-control-light-bg);
    fill: var(--ggl-text-faint);
  }
  .icon span {
    margin-top: -1px;
  }
  .icon .invalid {
    background-color: var(--ggl-text-destructive-color);
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    position: absolute;
    margin-top: -1.4rem;
    margin-left: 1.4rem;
  }
  .step {
    display: flex;
    align-items: center;
    place-content: center center;
    flex-direction: column;
  }
  .small .icon {
    border-color: var(--ggl-item-border);
    background-color: var(--ggl-item-border);
    height: 1.5rem;
    width: 1.5rem;
  }
  .small .icon.current {
    border-color: var(--ggl-item-primary-bg);
    background-color: var(--ggl-item-primary-bg);
  }
  .small .icon .invalid {
    margin-top: -1.15rem;
    margin-left: 1.15rem;
  }
  .small .icon.disabled {
    border-color: var(--ggl-text-primary-disabled);
    background-color: var(--ggl-text-primary-disabled);
  }
  .figure {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .line {
    flex-grow: 1;
    height: 0.25rem;
    margin-top: auto;
    margin-bottom: auto;
    background-color: var(--ggl-item-primary-bg);
  }
  .line.left {
    margin-right: 0.5rem;
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .line.right {
    margin-left: 0.5rem;
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .line.hidden {
    visibility: hidden;
  }
  .line.disabled {
    background-color: var(--ggl-text-faint);
  }
  .title {
    font-size: 12px;
    line-height: 1rem;
    display: inline-block;
    display: block;
    text-align: center;
    white-space: nowrap;
    overflow: visible;
    color: var(--ggl-item-primary-bg);
    padding: 0.5rem;
    cursor: pointer;
  }
  .title.current {
    font-weight: 600;
  }
  .title.disabled {
    color: var(--ggl-text-faint);
    cursor: default;
  }
  .skeleton {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
  }
  .skeleton :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .skeleton * {
    visibility: hidden !important;
  }
  .skeleton::after {
    display: none;
  }
}