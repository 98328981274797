/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    height: calc(var(--ggl-control-item-height) - 2px);
    display: flex;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    color: var(--ggl-text-color);
    flex-grow: 1;
    min-width: 0;
  }
  .input {
    background-color: transparent;
    border: none;
    outline: transparent;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--ggl-text-color);
    height: 100%;
    min-width: 0;
  }
  .input[disabled] {
    color: var(--ggl-text-disabled);
  }
  .icon {
    height: 1.25rem;
    width: 1.25rem;
    margin-left: 0.5rem;
    flex-shrink: 0;
    fill: var(--ggl-text-accent);
  }
  .icon.disabled {
    background-color: var(--ggl-disabled-bg);
    fill: var(--ggl-text-disabled);
  }
  .visible {
    visibility: visible;
  }
  .clear {
    margin-right: 0.25em;
  }
  .rightIcon {
    margin-right: 0.5rem;
  }
  .affix {
    background-color: var(--ggl-disabled-bg);
    color: var(--ggl-text-disabled);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 100%;
    align-items: center;
    display: flex;
  }
  .affix_span {
    padding-bottom: 1px;
  }
  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .placeholder,
  input::placeholder {
    color: var(--ggl-placeholder) !important;
  }
  input::autofill {
    color: var(--ggl-text) !important;
  }
  /* ********** Date inputs ********** */
  .dateInputRoot {
    width: 200px;
  }
  .dateInput {
    position: relative;
  }
  .dateInput:after {
    content: "";
    background-size: 1.25em 1.25em;
    width: calc(var(--ggl-control-item-height) - 2px);
    height: calc(var(--ggl-control-item-height) - 2px);
    background-image: var(--ggl-calendar-icon);
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    right: 2.125rem;
    margin-right: -2.125em;
    pointer-events: none;
  }
  .noValue {
    color: var(--ggl-placeholder) !important;
  }
  input[type=date] {
    min-width: 5rem;
  }
  input[type=datetime-local] {
    min-width: 10.5rem;
  }
  input[type=date] ::-webkit-inner-spin-button, input[type=date]::-webkit-outer-spin-button, input[type=date]::-webkit-calendar-picker-indicator,
  input[type=datetime-local] ::-webkit-inner-spin-button,
  input[type=datetime-local]::-webkit-outer-spin-button,
  input[type=datetime-local]::-webkit-calendar-picker-indicator {
    opacity: 0;
    max-width: 1.5rem;
    margin: 0;
    width: 1.5rem;
  }
  input[type=date]:focus,
  input[type=datetime-local]:focus {
    color: var(--ggl-text) !important;
  }
  ::-ms-reveal {
    display: none;
  }
  @-moz-document url-prefix() {
    .dateInput:after {
      display: none;
    }
  }
  /* ********** disabled ********** */
  .disabled .label {
    color: var(--ggl-text-disabled);
  }
  .disabled .fake_input {
    color: var(--ggl-text-disabled);
    border-color: var(--ggl-border-disabled);
    background-color: var(--ggl-disabled-bg);
  }
  .skeleton {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
  }
  .skeleton :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .skeleton * {
    visibility: hidden !important;
  }
  .skeleton::after {
    display: none;
  }
}