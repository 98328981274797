/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    display: flex;
    flex-direction: column;
    background: var(--ggl-panel-bg);
    border-radius: var(--ggl-border-radius-panel);
    border: 1px solid var(--ggl-panel-border);
    width: 100%;
    height: fit-content;
    padding: var(--ggl-form-padding);
    box-shadow: var(--ggl-drop-shadow-low);
    row-gap: var(--ggl-form-row-gap);
  }
  .legendHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .legend {
    font-size: 16px;
    font-weight: 600;
  }
  .helpButton {
    position: static;
    float: right;
    font-size: 0.75rem;
    border: none;
    background: none;
    line-height: 1.3rem;
    height: auto;
    max-height: 1.5rem;
  }
  .helpButton:hover {
    background: none;
  }
  .helpButton svg {
    background: none;
    fill: var(--ggl-item-secondary-active-bg);
    height: 1.25rem;
    width: 1.25rem;
  }
}