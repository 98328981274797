/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    border-radius: 1rem;
    background-color: var(--ggl-control-border);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }
  .imgContainer {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
  }
  .imgContainer .icon {
    height: 1rem;
    width: 1rem;
    fill: var(--ggl-item-active-text);
    margin: auto;
  }
  .backdrop {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    top: 0;
  }
  .blurred {
    width: inherit;
    filter: blur(0.5rem) brightness(80%);
  }
  .img {
    object-fit: cover;
    height: 100%;
    position: relative;
  }
  .inits {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    color: var(--ggl-text);
  }
  .inits span {
    text-transform: capitalize;
    font-weight: 500;
  }
  .inits span + span {
    margin-left: 0.2em;
  }
  .skeleton {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
  }
  .skeleton :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .skeleton * {
    visibility: hidden !important;
  }
  .skeleton::after {
    display: none;
  }
  .tiny {
    height: 1rem;
    width: 1rem;
    font-size: 0.25rem;
    border-radius: 0.25rem;
  }
  .tiny .icon {
    height: 0.5rem;
    width: 0.5rem;
  }
  .small {
    height: 2rem;
    width: 2rem;
    font-size: 0.5rem;
    border-radius: 0.25rem;
  }
  .small .icon {
    height: 0.5rem;
    width: 0.5rem;
  }
  .medium {
    height: 2.5rem;
    width: 2.5rem;
    font-size: 0.75rem;
    border-radius: 0.5rem;
  }
  .medium .icon {
    height: 0.5rem;
    width: 0.5rem;
  }
  .large {
    height: 3.5rem;
    width: 3.5rem;
    font-size: 1.1rem;
  }
  .x-large {
    height: 5.5rem;
    width: 5.5rem;
    font-size: 2rem;
  }
  .profile {
    height: 7.5rem;
    width: 7.5rem;
    font-size: 3rem;
  }
  .isLoading {
    display: none;
  }
  .round {
    border-radius: 50%;
  }
}