/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  :root {
    --ggl-chip-bg: rgb(242, 242, 242);
    --ggl-chip-text: rgb(35, 35, 35);
    --ggl-chip-primary-bg: rgb(187, 220, 255);
    --ggl-chip-attention-bg: rgb(255, 199, 139);
    --ggl-chip-success-bg: rgb(206, 242, 209);
    --ggl-chip-destructive-bg: rgb(255, 197, 197);
  }
  .chip {
    padding: 0 0.2rem 0rem 0.2rem;
    margin-right: 0.5rem;
    height: 1.5rem;
    border-radius: 0.25rem;
    background-color: var(--ggl-chip-bg);
    color: var(--ggl-chip-text);
    display: inline-block;
    vertical-align: middle;
    min-width: fit-content;
    max-width: fit-content;
    font-size: var(--ggl-font-size);
    line-height: 1.25rem;
  }
  .content {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 100%;
    margin: 0 0.25rem 0 0.25rem;
  }
  .text {
    height: 100%;
    line-height: 1.5rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .close {
    margin-left: 0.25rem;
    left: 0.5rem;
    position: relative;
    height: 1.5rem;
    width: 1.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .close:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .close:hover svg {
    fill: var(--ggl-chip-text);
  }
  .icon {
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.25rem;
    fill: var(--ggl-chip-text);
  }
  .message {
    height: auto;
  }
  .tiny {
    padding: 0;
    font-size: 12px;
    line-height: 1rem;
    line-height: 1rem;
  }
  .small {
    padding: 0;
    font-size: 12px;
    line-height: 1rem;
    line-height: 1rem;
  }
  .medium {
    padding: 0.125rem 0.25rem 0.125rem 0.25rem;
    font-weight: 400;
  }
  .large {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    font-weight: 400;
  }
  .x-large {
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 1.5rem;
  }
  .primary {
    background-color: var(--ggl-chip-primary-bg);
    color: var(--ggl-text-color-primary);
  }
  .attention {
    background-color: var(--ggl-chip-attention-bg);
    color: var(--ggl-text-color-attention);
  }
  .success {
    background-color: var(--ggl-chip-success-bg);
    color: var(--ggl-text-color-success);
  }
  .destructive {
    background-color: var(--ggl-chip-destructive-bg);
    color: var(--ggl-text-color-destructive);
  }
  .disabled {
    opacity: 0.5;
  }
  .skeleton {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
  }
  .skeleton :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .skeleton * {
    visibility: hidden !important;
  }
  .skeleton::after {
    display: none;
  }
}