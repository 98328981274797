/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    margin-bottom: 1rem;
  }
  .button {
    box-shadow: var(--ggl-drop-shadow-high);
    cursor: pointer;
    border-radius: var(--ggl-border-radius-panel);
    background-color: var(--ggl-panel-bg);
    width: 100%;
    height: 4.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  .icon {
    height: 2rem;
    width: 2rem;
    fill: var(--ggl-brand);
    margin-left: auto;
  }
  .content {
    box-shadow: var(--ggl-drop-shadow-low);
    background-color: var(--ggl-panel-bg);
    border-bottom-right-radius: var(--ggl-border-radius-panel);
    border-bottom-left-radius: var(--ggl-border-radius-panel);
    position: relative;
    top: -0.625rem;
  }
  .maxHeight {
    overflow-y: scroll;
    max-height: 20rem;
    overflow: auto;
  }
  .title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
  }
  .desc {
    font-size: var(--ggl-font-size);
    line-height: 1.25rem;
  }
  .close {
    max-height: 0;
    padding: 0;
    opacity: 0;
    transition: max-height 0.25s, opacity 0.25s;
  }
  .open {
    padding: 1.75rem 1.25rem;
    opacity: 1;
    transition: opacity 0.25s, max-height 0.25s;
  }
}