@layer reset, base, components, utilities, theme;
@layer reset {
  * {
    box-sizing: border-box;
  }
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    text-size-adjust: none;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  input[type=search]::-webkit-search-decoration,
  input[type=search]::-webkit-search-cancel-button,
  input[type=search]::-webkit-search-results-button,
  input[type=search]::-webkit-search-results-decoration {
    display: none;
  }
  p,
  li,
  th,
  td,
  textarea,
  input,
  a,
  label,
  cite,
  q,
  code,
  section {
    font-size: 14px;
    line-height: 1.25rem;
  }
}
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layers utilities {
  .mnpn {
    margin: 0;
    padding: 0;
  }
  .ptn, .pvn, .pan {
    padding-top: 0em;
  }
  .pts, .pvs, .pas {
    padding-top: 0.25rem;
  }
  .ptm, .pvm, .pam {
    padding-top: 0.5rem;
  }
  .ptl, .pvl, .pal {
    padding-top: 1rem;
  }
  .prn, .phn, .pan {
    padding-right: 0em;
  }
  .prs, .phs, .pas {
    padding-right: 0.25rem;
  }
  .prm, .phm, .pam {
    padding-right: 0.5rem;
  }
  .prl, .phl, .pal {
    padding-right: 1rem;
  }
  .pbn, .pvn, .pan {
    padding-bottom: 0em;
  }
  .pbs, .pvs, .pas {
    padding-bottom: 0.25rem;
  }
  .pbm, .pvm, .pam {
    padding-bottom: 0.5rem;
  }
  .pbl, .pvl, .pal {
    padding-bottom: 1rem;
  }
  .pln, .phn, .pan {
    padding-left: 0em;
  }
  .pls, .phs, .pas {
    padding-left: 0.25rem;
  }
  .plm, .phm, .pam {
    padding-left: 0.5rem;
  }
  .pll, .phl, .pal {
    padding-left: 1rem;
  }
  .mtn, .mvn, .man {
    margin-top: 0em;
  }
  .mts, .mvs, .mas {
    margin-top: 0.25rem;
  }
  .mtm, .mvm, .mam {
    margin-top: 0.5rem;
  }
  .mtl, .mvl, .mal {
    margin-top: 1rem;
  }
  .mrn, .mhn, .man {
    margin-right: 0em;
  }
  .mrs, .mhs, .mas {
    margin-right: 0.25rem;
  }
  .mrm, .mhm, .mam {
    margin-right: 0.5rem;
  }
  .mrl, .mhl, .mal {
    margin-right: 1rem;
  }
  .mbn, .mvn, .man {
    margin-bottom: 0em;
  }
  .mbs, .mvs, .mas {
    margin-bottom: 0.25rem;
  }
  .mbm, .mvm, .mam {
    margin-bottom: 0.5rem;
  }
  .mbl, .mvl, .mal {
    margin-bottom: 1rem;
  }
  .mln, .mhn, .man {
    margin-left: 0em;
  }
  .mls, .mhs, .mas {
    margin-left: 0.25rem;
  }
  .mlm, .mhm, .mam {
    margin-left: 0.5rem;
  }
  .mll, .mhl, .mal {
    margin-left: 1rem;
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes placeholderShimmer {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes skeleton {
  0% {
    opacity: 0.15;
  }
  50% {
    opacity: 0.35;
  }
  100% {
    opacity: 0.15;
  }
}
.fadeIn {
  animation-timing-function: linear;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.fadeOut {
  animation-timing-function: linear;
  animation-name: fadeOut;
  animation-duration: 0.5s;
}

/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer base {
  * {
    word-break: keep-all;
  }
  body {
    font-weight: 400;
    font-size: 100%;
    color: var(--ggl-text-color);
    background-color: var(--ggl-body-bg);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--ggl-text-color);
  }
  h1 {
    font-size: 28px;
    font-weight: 700;
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
  }
  h4,
  h5,
  h6 {
    font-size: var(--ggl-font-size);
    line-height: 1.25rem;
    font-weight: 600;
  }
  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    transition: background-color 3600s ease-in-out 0s;
  }
  a {
    text-decoration: none;
    color: var(--ggl-link-color);
  }
  p {
    font-size: var(--ggl-font-size);
    line-height: 1.25rem;
  }
}
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
:root {
  --ggl-label: rgb(50, 50, 50);
  --ggl-text: rgb(50, 50, 50);
  --ggl-brand: rgb(255, 132, 0);
  --ggl-border-radius-input: 0.25rem;
  --ggl-border-radius-panel: 0.5rem;
  --ggl-border-radius-checkbox: 1px;
  --ggl-loading-bg: #ebebeb;
  --ggl-placeholder: rgb(204, 204, 204);
  --ggl-backdrop: rgba(0, 0, 0, 0.75);
  --ggl-body-bg: rgb(242, 242, 242);
  --ggl-text-size: 100%;
  --ggl-link-color: rgb(0, 123, 255);
  --ggl-link-hover: rgb(137, 194, 255);
  --ggl-link-visited: rgb(0, 123, 255);
  --ggl-text-color: rgb(50, 50, 50);
  --ggl-text-color-primary: rgb(8, 42, 77);
  --ggl-text-color-success: rgb(14, 49, 14);
  --ggl-text-color-attention: rgb(75, 33, 3);
  --ggl-text-color-destructive: rgb(86, 19, 19);
  --ggl-text-faint: rgb(138, 138, 138);
  --ggl-text-accent: rgb(35, 35, 35);
  --ggl-text-disabled: rgb(204, 204, 204);
  --ggl-text-destructive: rgb(255, 71, 71);
  --ggl-font-size: 14px;
  --ggl-text-primary-color: rgb(0, 123, 255);
  --ggl-text-primary-accent: rgb(0, 114, 229);
  --ggl-text-primary-disabled: rgb(204, 204, 204);
  --ggl-text-attention-color: rgb(255, 132, 0);
  --ggl-text-attention-accent: rgb(222, 115, 0);
  --ggl-text-attention-disabled: rgb(204, 204, 204);
  --ggl-text-destructive-color: rgb(255, 71, 71);
  --ggl-text-destructive-accent: rgb(211, 37, 37);
  --ggl-text-destructive-disabled: rgb(204, 204, 204);
  --ggl-text-success-light-bg: rgb(206, 242, 209);
  --ggl-text-success-color: rgb(59, 181, 69);
  --ggl-text-success-accent: rgb(3, 146, 3);
  --ggl-text-success-disabled: rgb(204, 204, 204);
  --ggl-menu-heading: rgb(0, 123, 255);
  --ggl-table-heading-bg: linear-gradient(white, rgb(253, 253, 253));
  --ggl-form-bg: rgb(253, 253, 253);
  --ggl-form-border: #eaeaea;
  --ggl-form-row-gap: 0.75rem;
  --ggl-form-col-gap: 1.5rem;
  --ggl-form-padding: 1.5rem 1.5rem 1rem 1.5rem;
  --ggl-control-bg: rgb(255, 255, 255);
  --ggl-control-light-bg: rgb(253, 253, 253);
  --ggl-control-bg-error: rgb(255, 255, 255);
  --ggl-control-error: rgb(255, 71, 71);
  --ggl-control-item-height: 2rem;
  --ggl-control-border: rgb(204, 204, 204);
  --ggl-control-border-hover: rgb(204, 204, 204), rgb(138, 138, 138);
  --ggl-control-border-light: #eaeaea;
  --ggl-control-border-error: rgb(255, 71, 71);
  --ggl-control-button-hover: rgb(242, 242, 242);
  --ggl-control-border-active: rgb(255, 132, 0);
  --ggl-control-button: transparent;
  --ggl-control-button-active: rgb(138, 138, 138);
  --ggl-control-collapsed: rgb(79, 79, 79);
  --ggl-control-checkbox-border: rgb(79, 79, 79);
  --ggl-control-checkbox-box-shadow: inset;
  --ggl-control-checkbox-box-shadow-checked: inset var(--ggl-item-primary-bg);
  --ggl-control-checkbox-readonly-border: #eaeaea;
  --ggl-control-checkbox-readonly-selected: rgb(187, 220, 255);
  --ggl-panel-bg: rgb(255, 255, 255);
  --ggl-panel-bg-alt: rgb(253, 253, 253);
  --ggl-panel-border: rgb(204, 204, 204);
  --ggl-panel-border-alt: rgb(242, 242, 242);
  --ggl-disabled-bg: rgb(242, 242, 242);
  --ggl-border: rgb(50, 50, 50);
  --ggl-border-disabled: rgb(204, 204, 204);
  --ggl-border-readonly: rgb(253, 253, 253);
  --ggl-border-readonly-focus: #eaeaea;
  --ggl-drop-shadow-low: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
  --ggl-drop-shadow-inset: inset 0px 0 4px 2px rgba(0, 0, 0, 0.03);
  --ggl-drop-shadow-medium: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.13);
  --ggl-drop-shadow-high: rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px 2px;
  --ggl-item-bg: transparent;
  --ggl-item-text: rgb(0, 123, 255);
  --ggl-item-border: rgb(187, 220, 255);
  --ggl-item-border-tint: rgb(187, 220, 255);
  --ggl-item-hover-bg: rgb(218, 236, 255);
  --ggl-item-hover-text: rgb(0, 123, 255);
  --ggl-item-hover-border: rgb(187, 220, 255);
  --ggl-item-hover-border-tint: rgb(187, 220, 255);
  --ggl-item-active-bg: rgb(187, 220, 255);
  --ggl-item-active-text: rgb(0, 123, 255);
  --ggl-item-active-border: rgb(187, 220, 255);
  --ggl-item-active-border-tint: rgb(187, 220, 255);
  --ggl-item-disabled-bg: rgb(255, 255, 255);
  --ggl-item-disabled-text: rgb(204, 204, 204);
  --ggl-item-disabled-border: rgb(242, 242, 242);
  --ggl-item-primary-bg: rgb(0, 123, 255);
  --ggl-item-primary-text: rgb(255, 255, 255);
  --ggl-item-primary-border: rgb(0, 123, 255);
  --ggl-item-primary-border-tint: rgb(0, 123, 255);
  --ggl-item-primary-hover-bg: rgb(4, 97, 191);
  --ggl-item-primary-hover-text: rgb(255, 255, 255);
  --ggl-item-primary-hover-border: rgb(4, 97, 191);
  --ggl-item-primary-hover-border-tint: rgb(4, 97, 191);
  --ggl-item-primary-active-bg: rgb(8, 42, 77);
  --ggl-item-primary-active-text: rgb(255, 255, 255);
  --ggl-item-primary-active-border: rgb(8, 42, 77);
  --ggl-item-primary-active-border-tint: rgb(8, 42, 77);
  --ggl-item-primary-command-bg: rgb(187, 220, 255);
  --ggl-item-attention-bg: rgb(255, 132, 0);
  --ggl-item-attention-text: rgb(255, 255, 255);
  --ggl-item-attention-border: rgb(255, 132, 0);
  --ggl-item-attention-border-tint: rgb(255, 132, 0);
  --ggl-item-attention-hover-bg: rgb(191, 89, 14);
  --ggl-item-attention-hover-text: rgb(255, 255, 255);
  --ggl-item-attention-hover-border: rgb(191, 89, 14);
  --ggl-item-attention-hover-border-tint: rgb(191, 89, 14);
  --ggl-item-attention-active-bg: rgb(75, 33, 3);
  --ggl-item-attention-active-text: rgb(255, 255, 255);
  --ggl-item-attention-active-border: rgb(75, 33, 3);
  --ggl-item-attention-active-border-tint: rgb(75, 33, 3);
  --ggl-item-attention-command-bg: rgb(255, 199, 139);
  --ggl-item-destructive-bg: rgb(255, 71, 71);
  --ggl-item-destructive-text: rgb(255, 255, 255);
  --ggl-item-destructive-border: rgb(255, 71, 71);
  --ggl-item-destructive-border-tint: rgb(255, 71, 71);
  --ggl-item-destructive-hover-bg: rgb(173, 1, 1);
  --ggl-item-destructive-hover-text: rgb(255, 255, 255);
  --ggl-item-destructive-hover-border: rgb(173, 1, 1);
  --ggl-item-destructive-hover-border-tint: rgb(173, 1, 1);
  --ggl-item-destructive-active-bg: rgb(86, 19, 19);
  --ggl-item-destructive-active-text: rgb(255, 255, 255);
  --ggl-item-destructive-active-border: rgb(86, 19, 19);
  --ggl-item-destructive-active-border-tint: rgb(86, 19, 19);
  --ggl-item-destructive-command-bg: rgb(255, 197, 197);
  --ggl-item-success-bg: rgb(59, 181, 69);
  --ggl-item-success-text: rgb(255, 255, 255);
  --ggl-item-success-border: rgb(59, 181, 69);
  --ggl-item-success-border-tint: rgb(59, 181, 69);
  --ggl-item-success-hover-bg: rgb(28, 109, 28);
  --ggl-item-success-hover-text: rgb(255, 255, 255);
  --ggl-item-success-hover-border: rgb(28, 109, 28);
  --ggl-item-success-hover-border-tint: rgb(28, 109, 28);
  --ggl-item-success-active-bg: rgb(14, 49, 14);
  --ggl-item-success-active-text: rgb(255, 255, 255);
  --ggl-item-success-active-border: rgb(14, 49, 14);
  --ggl-item-success-active-border-tint: rgb(14, 49, 14);
  --ggl-item-success-command-bg: rgb(206, 242, 209);
  --ggl-item-secondary-bg: transparent;
  --ggl-item-secondary-text: rgb(0, 123, 255);
  --ggl-item-secondary-border: rgb(0, 123, 255);
  --ggl-item-secondary-border-tint: rgb(0, 123, 255);
  --ggl-item-secondary-hover-bg: rgb(218, 236, 255);
  --ggl-item-secondary-hover-text: rgb(0, 123, 255);
  --ggl-item-secondary-hover-border: rgb(0, 123, 255);
  --ggl-item-secondary-hover-border-tint: rgb(0, 123, 255);
  --ggl-item-secondary-active-bg: rgb(137, 194, 255);
  --ggl-item-secondary-active-text: rgb(0, 123, 255);
  --ggl-item-secondary-active-border: rgb(0, 123, 255);
  --ggl-item-secondary-active-border-tint: rgb(0, 123, 255);
  --ggl-msg-primary-bg: rgb(218, 236, 255);
  --ggl-msg-primary-text: rgb(0, 123, 255);
  --ggl-msg-primary-border: rgb(0, 123, 255);
  --ggl-msg-success-bg: rgb(243, 255, 244);
  --ggl-msg-success-text: rgb(59, 181, 69);
  --ggl-msg-success-border: rgb(59, 181, 69);
  --ggl-msg-destructive-bg: rgb(255, 232, 232);
  --ggl-msg-destructive-text: rgb(255, 71, 71);
  --ggl-msg-destructive-border: rgb(255, 71, 71);
  --ggl-msg-attention-bg: rgb(255, 249, 233);
  --ggl-msg-attention-text: rgb(255, 132, 0);
  --ggl-msg-attention-border: rgb(255, 132, 0);
  --ggl-calendar-icon: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOS4yOCAyMS40MiI+PHBhdGggZD0iTTQuOTgsMTMuMDFjLS4zLDAtLjU2LS4xLS43Ni0uMzEtLjIxLS4yMS0uMzEtLjQ2LS4zMS0uNzZzLjEtLjU2LC4zMS0uNzYsLjQ2LS4zMSwuNzYtLjMxYy4zLDAsLjU2LC4xLC43NiwuMzEsLjIxLC4yMSwuMzEsLjQ2LC4zMSwuNzZzLS4xLC41Ni0uMzEsLjc2Yy0uMjEsLjIxLS40NiwuMzEtLjc2LC4zMVptNC43NCwwYy0uMywwLS41Ni0uMS0uNzYtLjMxcy0uMzEtLjQ2LS4zMS0uNzYsLjEtLjU2LC4zMS0uNzZjLjIxLS4yMSwuNDYtLjMxLC43Ni0uMzEsLjMsMCwuNTYsLjEsLjc2LC4zMSwuMjEsLjIxLC4zMSwuNDYsLjMxLC43NnMtLjEsLjU2LS4zMSwuNzZjLS4yMSwuMjEtLjQ2LC4zMS0uNzYsLjMxWm00LjU1LDBjLS4zLDAtLjU2LS4xLS43Ni0uMzFzLS4zMS0uNDYtLjMxLS43NiwuMS0uNTYsLjMxLS43NmMuMjEtLjIxLC40Ni0uMzEsLjc2LS4zMSwuMywwLC41NiwuMSwuNzYsLjMxLC4yMSwuMjEsLjMxLC40NiwuMzEsLjc2cy0uMSwuNTYtLjMxLC43NmMtLjIxLC4yMS0uNDYsLjMxLS43NiwuMzFaTTEuNjEsMjEuNDJjLS40MywwLS44LS4xNi0xLjEyLS40OC0uMzItLjMyLS40OC0uNy0uNDgtMS4xMlYzLjIxYzAtLjQzLC4xNi0uOCwuNDgtMS4xMiwuMzItLjMyLC43LS40OCwxLjEyLS40OGgxLjc0Vi44M2MwLS4yMywuMDgtLjQzLC4yNC0uNTksLjE2LS4xNiwuMzctLjI0LC42Mi0uMjRzLjQ2LC4wOCwuNjMsLjI0Yy4xNywuMTYsLjI1LC4zNywuMjUsLjYydi43NUgxNC4xOVYuODNjMC0uMjMsLjA4LS40MywuMjQtLjU5LC4xNi0uMTYsLjM3LS4yNCwuNjItLjI0cy40NiwuMDgsLjYzLC4yNGMuMTcsLjE2LC4yNSwuMzcsLjI1LC42MnYuNzVoMS43NGMuNDMsMCwuOCwuMTYsMS4xMiwuNDgsLjMyLC4zMiwuNDgsLjcsLjQ4LDEuMTJWMTkuODFjMCwuNDMtLjE2LC44LS40OCwxLjEyLS4zMiwuMzItLjcsLjQ4LTEuMTIsLjQ4SDEuNjFabTAtMS42MUgxNy42N1Y4LjNIMS42MXYxMS41MWgwWk0xLjYxLDYuNjlIMTcuNjdWMy4yMUgxLjYxdjMuNDhabTAsMFYzLjIxaDB2My40OFoiLz48L3N2Zz4=);
}

[data-theme=dark] {
  --ggl-label: rgb(204, 204, 204);
  --ggl-text: rgb(204, 204, 204);
  --ggl-brand: rgb(255, 132, 0);
  --ggl-border-radius-input: 0.25rem;
  --ggl-border-radius-panel: 0.5rem;
  --ggl-border-radius-checkbox: 1px;
  --ggl-loading-bg: #ebebeb;
  --ggl-placeholder: rgb(138, 138, 138);
  --ggl-backdrop: rgba(0, 0, 0, 0.8);
  --ggl-body-bg: rgb(35, 35, 35);
  --ggl-text-size: 100%;
  --ggl-link-color: rgb(0, 114, 229);
  --ggl-link-hover: rgb(4, 97, 191);
  --ggl-link-visited: rgb(4, 97, 191);
  --ggl-text-color: rgb(204, 204, 204);
  --ggl-text-color-primary: rgb(8, 42, 77);
  --ggl-text-color-success: rgb(14, 49, 14);
  --ggl-text-color-attention: rgb(75, 33, 3);
  --ggl-text-color-destructive: rgb(86, 19, 19);
  --ggl-text-faint: rgb(138, 138, 138);
  --ggl-text-accent: rgb(253, 253, 253);
  --ggl-text-disabled: rgb(79, 79, 79);
  --ggl-text-destructive: rgb(255, 133, 133);
  --ggl-font-size: 14px;
  --ggl-text-primary-color: rgb(137, 194, 255);
  --ggl-text-primary-accent: rgb(187, 220, 255);
  --ggl-text-primary-disabled: rgb(79, 79, 79);
  --ggl-text-attention-color: rgb(255, 166, 71);
  --ggl-text-attention-accent: rgb(255, 199, 139);
  --ggl-text-attention-disabled: rgb(79, 79, 79);
  --ggl-text-destructive-color: rgb(255, 133, 133);
  --ggl-text-destructive-accent: rgb(255, 197, 197);
  --ggl-text-destructive-disabled: rgb(79, 79, 79);
  --ggl-text-success-light-bg: rgb(28, 109, 28);
  --ggl-text-success-color: rgb(131, 255, 139);
  --ggl-text-success-accent: rgb(206, 242, 209);
  --ggl-text-success-disabled: rgb(79, 79, 79);
  --ggl-menu-heading: rgb(0, 123, 255);
  --ggl-table-heading-bg: linear-gradient(white, rgb(253, 253, 253));
  --ggl-form-bg: rgb(50, 50, 50);
  --ggl-form-border: rgb(79, 79, 79);
  --ggl-form-row-gap: 0.75rem;
  --ggl-form-col-gap: 1.5rem;
  --ggl-form-padding: 1.5rem 1.5rem 1rem 1.5rem;
  --ggl-control-bg: rgb(35, 35, 35);
  --ggl-control-light-bg: rgb(50, 50, 50);
  --ggl-control-bg-error: rgb(35, 35, 35);
  --ggl-control-error: rgb(255, 71, 71);
  --ggl-control-item-height: 2rem;
  --ggl-control-border: rgb(138, 138, 138);
  --ggl-control-border-hover: rgb(17, 17, 17);
  --ggl-control-border-light: rgb(79, 79, 79);
  --ggl-control-border-error: rgb(255, 71, 71);
  --ggl-control-button-hover: rgb(79, 79, 79);
  --ggl-control-border-active: rgb(255, 132, 0);
  --ggl-control-button: transparent;
  --ggl-control-button-active: rgb(138, 138, 138);
  --ggl-control-collapsed: rgb(138, 138, 138);
  --ggl-control-checkbox-border: rgb(204, 204, 204);
  --ggl-control-checkbox-box-shadow: 0;
  --ggl-control-checkbox-box-shadow-checked: 0 var(--ggl-item-primary-bg);
  --ggl-control-checkbox-readonly-border: rgb(79, 79, 79);
  --ggl-control-checkbox-readonly-selected: rgb(4, 97, 191);
  --ggl-panel-bg: rgb(35, 35, 35);
  --ggl-panel-bg-alt: rgb(50, 50, 50);
  --ggl-panel-border: rgb(79, 79, 79);
  --ggl-panel-border-alt: rgb(79, 79, 79);
  --ggl-disabled-bg: rgb(50, 50, 50);
  --ggl-border: rgb(50, 50, 50);
  --ggl-border-disabled: rgb(79, 79, 79);
  --ggl-border-readonly: rgb(50, 50, 50);
  --ggl-border-readonly-focus: rgb(79, 79, 79);
  --ggl-drop-shadow-low: 0px 2px 2px 0px rgba(0, 0, 0, 0.11);
  --ggl-drop-shadow-inset: inset 0px 0 4px 2px rgba(0, 0, 0, 0.05);
  --ggl-drop-shadow-medium: 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.11);
  --ggl-drop-shadow-high: rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 2px;
  --ggl-item-bg: transparent;
  --ggl-item-text: rgb(137, 194, 255);
  --ggl-item-border: rgb(4, 97, 191);
  --ggl-item-border-tint: rgb(4, 97, 191);
  --ggl-item-hover-bg: rgb(8, 42, 77);
  --ggl-item-hover-text: rgb(137, 194, 255);
  --ggl-item-hover-border: rgb(4, 97, 191);
  --ggl-item-hover-border-tint: rgb(4, 97, 191);
  --ggl-item-active-bg: rgb(4, 97, 191);
  --ggl-item-active-text: rgb(137, 194, 255);
  --ggl-item-active-border: rgb(4, 97, 191);
  --ggl-item-active-border-tint: rgb(4, 97, 191);
  --ggl-item-disabled-bg: rgb(35, 35, 35);
  --ggl-item-disabled-text: rgb(79, 79, 79);
  --ggl-item-disabled-border: rgb(79, 79, 79);
  --ggl-item-primary-bg: rgb(137, 194, 255);
  --ggl-item-primary-text: rgb(8, 42, 77);
  --ggl-item-primary-border: rgb(137, 194, 255);
  --ggl-item-primary-border-tint: rgb(137, 194, 255);
  --ggl-item-primary-hover-bg: rgb(187, 220, 255);
  --ggl-item-primary-hover-text: rgb(8, 42, 77);
  --ggl-item-primary-hover-border: rgb(187, 220, 255);
  --ggl-item-primary-hover-border-tint: rgb(187, 220, 255);
  --ggl-item-primary-active-bg: rgb(218, 236, 255);
  --ggl-item-primary-active-text: rgb(8, 42, 77);
  --ggl-item-primary-active-border: rgb(218, 236, 255);
  --ggl-item-primary-active-border-tint: rgb(218, 236, 255);
  --ggl-item-primary-command-bg: rgb(8, 42, 77);
  --ggl-item-attention-bg: rgb(255, 166, 71);
  --ggl-item-attention-text: rgb(75, 33, 3);
  --ggl-item-attention-border: rgb(255, 166, 71);
  --ggl-item-attention-border-tint: rgb(255, 166, 71);
  --ggl-item-attention-hover-bg: rgb(255, 199, 139);
  --ggl-item-attention-hover-text: rgb(75, 33, 3);
  --ggl-item-attention-hover-border: rgb(255, 199, 139);
  --ggl-item-attention-hover-border-tint: rgb(255, 199, 139);
  --ggl-item-attention-active-bg: rgb(255, 249, 233);
  --ggl-item-attention-active-text: rgb(75, 33, 3);
  --ggl-item-attention-active-border: rgb(255, 249, 233);
  --ggl-item-attention-active-border-tint: rgb(255, 249, 233);
  --ggl-item-attention-command-bg: rgb(75, 33, 3);
  --ggl-item-destructive-bg: rgb(255, 133, 133);
  --ggl-item-destructive-text: rgb(86, 19, 19);
  --ggl-item-destructive-border: rgb(255, 133, 133);
  --ggl-item-destructive-border-tint: rgb(255, 133, 133);
  --ggl-item-destructive-hover-bg: rgb(255, 197, 197);
  --ggl-item-destructive-hover-text: rgb(86, 19, 19);
  --ggl-item-destructive-hover-border: rgb(255, 197, 197);
  --ggl-item-destructive-hover-border-tint: rgb(255, 197, 197);
  --ggl-item-destructive-active-bg: rgb(255, 232, 232);
  --ggl-item-destructive-active-text: rgb(86, 19, 19);
  --ggl-item-destructive-active-border: rgb(255, 232, 232);
  --ggl-item-destructive-active-border-tint: rgb(255, 232, 232);
  --ggl-item-destructive-command-bg: rgb(86, 19, 19);
  --ggl-item-success-bg: rgb(131, 255, 139);
  --ggl-item-success-text: rgb(14, 49, 14);
  --ggl-item-success-border: rgb(131, 255, 139);
  --ggl-item-success-border-tint: rgb(131, 255, 139);
  --ggl-item-success-hover-bg: rgb(206, 242, 209);
  --ggl-item-success-hover-text: rgb(14, 49, 14);
  --ggl-item-success-hover-border: rgb(206, 242, 209);
  --ggl-item-success-hover-border-tint: rgb(206, 242, 209);
  --ggl-item-success-active-bg: rgb(243, 255, 244);
  --ggl-item-success-active-text: rgb(14, 49, 14);
  --ggl-item-success-active-border: rgb(243, 255, 244);
  --ggl-item-success-active-border-tint: rgb(243, 255, 244);
  --ggl-item-success-command-bg: rgb(14, 49, 14);
  --ggl-item-secondary-bg: transparent;
  --ggl-item-secondary-text: rgb(137, 194, 255);
  --ggl-item-secondary-border: rgb(137, 194, 255);
  --ggl-item-secondary-border-tint: rgb(137, 194, 255);
  --ggl-item-secondary-hover-bg: rgb(4, 97, 191);
  --ggl-item-secondary-hover-text: rgb(137, 194, 255);
  --ggl-item-secondary-hover-border: rgb(137, 194, 255);
  --ggl-item-secondary-hover-border-tint: rgb(137, 194, 255);
  --ggl-item-secondary-active-bg: rgb(8, 42, 77);
  --ggl-item-secondary-active-text: rgb(137, 194, 255);
  --ggl-item-secondary-active-border: rgb(137, 194, 255);
  --ggl-item-secondary-active-border-tint: rgb(137, 194, 255);
  --ggl-msg-primary-bg: rgb(8, 42, 77);
  --ggl-msg-primary-text: rgb(0, 114, 229);
  --ggl-msg-primary-border: rgb(0, 114, 229);
  --ggl-msg-success-bg: rgb(14, 49, 14);
  --ggl-msg-success-text: rgb(3, 146, 3);
  --ggl-msg-success-border: rgb(3, 146, 3);
  --ggl-msg-destructive-bg: rgb(86, 19, 19);
  --ggl-msg-destructive-text: rgb(211, 37, 37);
  --ggl-msg-destructive-border: rgb(211, 37, 37);
  --ggl-msg-attention-bg: rgb(75, 33, 3);
  --ggl-msg-attention-text: rgb(222, 115, 0);
  --ggl-msg-attention-border: rgb(222, 115, 0);
  --ggl-calendar-icon: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMSIgdmlld0JveD0iMCAwIDE5LjI4IDIxLjQyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxkZWZzLz4KICA8cGF0aCBkPSJNNC45OCwxMy4wMWMtLjMsMC0uNTYtLjEtLjc2LS4zMS0uMjEtLjIxLS4zMS0uNDYtLjMxLS43NnMuMS0uNTYsLjMxLS43NiwuNDYtLjMxLC43Ni0uMzFjLjMsMCwuNTYsLjEsLjc2LC4zMSwuMjEsLjIxLC4zMSwuNDYsLjMxLC43NnMtLjEsLjU2LS4zMSwuNzZjLS4yMSwuMjEtLjQ2LC4zMS0uNzYsLjMxWm00Ljc0LDBjLS4zLDAtLjU2LS4xLS43Ni0uMzFzLS4zMS0uNDYtLjMxLS43NiwuMS0uNTYsLjMxLS43NmMuMjEtLjIxLC40Ni0uMzEsLjc2LS4zMSwuMywwLC41NiwuMSwuNzYsLjMxLC4yMSwuMjEsLjMxLC40NiwuMzEsLjc2cy0uMSwuNTYtLjMxLC43NmMtLjIxLC4yMS0uNDYsLjMxLS43NiwuMzFabTQuNTUsMGMtLjMsMC0uNTYtLjEtLjc2LS4zMXMtLjMxLS40Ni0uMzEtLjc2LC4xLS41NiwuMzEtLjc2Yy4yMS0uMjEsLjQ2LS4zMSwuNzYtLjMxLC4zLDAsLjU2LC4xLC43NiwuMzEsLjIxLC4yMSwuMzEsLjQ2LC4zMSwuNzZzLS4xLC41Ni0uMzEsLjc2Yy0uMjEsLjIxLS40NiwuMzEtLjc2LC4zMVpNMS42MSwyMS40MmMtLjQzLDAtLjgtLjE2LTEuMTItLjQ4LS4zMi0uMzItLjQ4LS43LS40OC0xLjEyVjMuMjFjMC0uNDMsLjE2LS44LC40OC0xLjEyLC4zMi0uMzIsLjctLjQ4LDEuMTItLjQ4aDEuNzRWLjgzYzAtLjIzLC4wOC0uNDMsLjI0LS41OSwuMTYtLjE2LC4zNy0uMjQsLjYyLS4yNHMuNDYsLjA4LC42MywuMjRjLjE3LC4xNiwuMjUsLjM3LC4yNSwuNjJ2Ljc1SDE0LjE5Vi44M2MwLS4yMywuMDgtLjQzLC4yNC0uNTksLjE2LS4xNiwuMzctLjI0LC42Mi0uMjRzLjQ2LC4wOCwuNjMsLjI0Yy4xNywuMTYsLjI1LC4zNywuMjUsLjYydi43NWgxLjc0Yy40MywwLC44LC4xNiwxLjEyLC40OCwuMzIsLjMyLC40OCwuNywuNDgsMS4xMlYxOS44MWMwLC40My0uMTYsLjgtLjQ4LDEuMTItLjMyLC4zMi0uNywuNDgtMS4xMiwuNDhIMS42MVptMC0xLjYxSDE3LjY3VjguM0gxLjYxdjExLjUxaDBaTTEuNjEsNi42OUgxNy42N1YzLjIxSDEuNjF2My40OFptMCwwVjMuMjFoMHYzLjQ4WiIgc3R5bGU9ImZpbGw6IHJnYigxMzgsIDEzOCwgMTM4KTsiLz4KPC9zdmc+);
}