$brand: "ggl";
.textarea {
  resize: none;
  width: 100%;
  height: 12rem;
  border-radius: var(--ggl-border-radius-input);
  border: solid thin var(--ggl-control-border-light);
  padding: 0.5rem;
  background-color: var(--ggl-control-bg);
  color:var(--ggl-text-accent);
  &:focus {
    border: solid thin var(--ggl-control-border-light);
    outline: none !important;
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(1, calc(100% - 0.5rem));
}

.levels {
  margin-bottom: 1rem;

  ul,
  .level_notice {
    display: flex;
    justify-content: space-between;
    div {
      color: var(--ggl-text-faint);
      width: 4.5rem;
      text-align: center;
    }
  }
  li {
    cursor: pointer;
    display: block;
    padding: 0.5rem 2rem;
    border-radius: var(--ggl-border-radius-input);
    border: solid thin var(--ggl-control-border-light);
    &:hover {
      background-color: var(--ggl-control-button-hover);
    }

    &.selected,
    &.selected:hover {
      background-color: var(--ggl-item-primary-bg);
      border-color: var(--ggl-item-primary-border);
      color: var(--ggl-item-primary-text);
    }
  }
}

.hidden_input {
  display: none;
}

.required_notice {
  margin-left: 0.2rem;
  color: var(--ggl-control-border-error);
}

@media only screen and (max-width: 580px) {
  .levels li {
    padding: 0.5rem 1rem;
  }
  .level_notice div {
    width: auto;
  }
}

@media only screen and (max-width: 300px) {
  .levels li {
    padding: 0.5rem 0.75rem;
  }
  .level_notice div {
    width: auto;
  }
}

@media only screen and (max-height: 620px) {
  .textarea {
    height: 6rem;
  }
}
