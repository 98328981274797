/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .textInput input {
    width: 100%;
  }
  .textInput input[readonly] {
    cursor: default;
  }
  .datalist {
    position: fixed;
    border: thin solid var(--ggl-control-border);
    border-radius: var(--ggl-border-radius-input);
    font-family: sans-serif;
    padding: 0 8px 0.7em 8px;
    overflow-y: auto;
    display: block;
    background-color: var(--ggl-control-bg);
    box-shadow: var(--ggl-drop-shadow-low);
    z-index: 1000;
    text-align: left;
  }
  .datalist li {
    display: block;
    min-width: var(--ggl-control-item-height);
    padding-top: 0.35em;
    padding-left: 0.75em;
    border-radius: var(--ggl-border-radius-input);
    margin-top: 0.5em;
    cursor: pointer;
    line-height: 1.2rem;
    height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
  }
  .datalist li.active:before {
    content: "";
    height: 1rem;
    width: 0.25rem;
    left: 0rem;
    top: 0.45rem;
    border-radius: 0.125rem;
    background-color: var(--ggl-brand);
    position: absolute;
    z-index: 1000;
  }
  .datalist li:hover,
  .datalist li.current,
  .datalist li.active {
    background-color: var(--ggl-control-button-hover);
  }
}