/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    color: var(--ggl-text-color);
    font-size: 1.25rem;
    width: fit-content;
  }
  .root svg {
    width: 1.25rem !important;
    height: 1.25rem !important;
    fill: var(--ggl-text-color);
    margin-right: 0.5rem;
    vertical-align: middle;
  }
  .root a,
  .root span {
    text-decoration: none;
    color: inherit;
    line-height: 1.625rem;
    vertical-align: middle;
  }
  .root p {
    font-weight: normal;
    line-height: 1.1875rem;
  }
  .root.small {
    font-size: 12px;
    line-height: 1rem;
  }
  .root.small span {
    line-height: 1.1875rem;
  }
  .root.small p {
    font-size: 0.75rem;
    line-height: 1.1875rem;
    margin: 0;
  }
  .root.small svg {
    width: 1rem;
    height: 1rem;
  }
  .root.medium {
    font-size: var(--ggl-font-size);
    line-height: 1.25rem;
    font-weight: 600;
  }
  .root.medium svg {
    width: 1rem;
    height: 1rem;
  }
  .root.large {
    font-weight: 600;
  }
  .root.x-large {
    font-weight: 600;
    font-size: 1.75rem;
  }
  .root.x-large span {
    line-height: 2.25rem;
  }
  .root.x-large svg {
    width: 1.75rem;
    height: 1.75rem;
  }
  .root.primary {
    color: var(--ggl-msg-primary-text);
  }
  .root.primary svg {
    fill: var(--ggl-msg-primary-text);
  }
  .root.success {
    color: var(--ggl-msg-success-text);
  }
  .root.success svg {
    fill: var(--ggl-msg-success-text);
  }
  .root.attention {
    color: var(--ggl-text-accent);
  }
  .root.attention svg {
    fill: var(--ggl-msg-attention-text);
  }
  .root.destructive {
    color: var(--ggl-msg-destructive-text);
  }
  .root.destructive svg {
    fill: var(--ggl-msg-destructive-text);
  }
  .skeleton span,
  .skeleton a,
  .skeleton p {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
  }
  .skeleton span :global,
  .skeleton a :global,
  .skeleton p :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .skeleton span *,
  .skeleton a *,
  .skeleton p * {
    visibility: hidden !important;
  }
  .skeleton span::after,
  .skeleton a::after,
  .skeleton p::after {
    display: none;
  }
  .truncate {
    overflow: hidden;
  }
  .truncate * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}