/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .textarea {
    margin: 0.25rem 0.5rem 0.25rem 0.5rem;
    height: calc(100% - 0.5rem);
  }
}