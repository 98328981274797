/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .active [data-type=state],
  .active:hover [data-type=state] {
    fill: var(--ggl-item-success-bg);
  }
  .pending [data-type=state],
  .pending:hover [data-type=state] {
    fill: var(--ggl-item-primary-bg);
  }
  .inactive [data-type=state],
  .inactive:hover [data-type=state],
  .expired [data-type=state],
  .expired:hover [data-type=state] {
    fill: var(--ggl-item-destructive-bg);
  }
  .attention [data-type=state],
  .attention:hover [data-type=state] {
    fill: var(--ggl-item-attention-bg);
  }
  .skeleton [data-type=state] {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
    fill: transparent;
  }
  .skeleton [data-type=state] :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .skeleton [data-type=state] * {
    visibility: hidden !important;
  }
  .skeleton [data-type=state]::after {
    display: none;
  }
}