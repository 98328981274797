@charset "UTF-8";
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .navBar {
    height: 2.5rem;
    position: relative;
  }
  .navContent {
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  .trailContainer {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: flex-end;
  }
  .navCol {
    position: relative;
    outline: none;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .navText > * {
    color: inherit;
    text-align: center;
    justify-self: center;
    align-self: center;
    outline: none;
    font-size: 0.9rem;
    white-space: nowrap;
    padding-right: 0.28125rem;
    padding-bottom: 0.125rem;
  }
  .navBack {
    height: 1.5rem;
    cursor: pointer;
    margin-left: 0.75rem;
  }
  .navBackCol {
    margin-right: 1.25rem;
  }
  .trail {
    height: 100%;
    visibility: hidden;
  }
  .trail.lastTrail {
    visibility: visible;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
  }
  @media only screen and (min-width: 860px) {
    .navBar {
      height: 3rem;
    }
    .navBack {
      margin-left: 0;
    }
    .chevron::before {
      content: "›";
      margin-right: 0.6rem;
      font-size: 140%;
      font-weight: 100;
      cursor: none;
      pointer-events: none;
      margin-bottom: 0.3125rem;
      margin-left: 0.28125rem;
    }
    .trail {
      visibility: visible;
    }
    .trail.lastTrail {
      position: static;
      top: auto;
      left: auto;
      transform: none;
    }
  }
}