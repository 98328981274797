/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .tree {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .treeItem {
    margin: 0.35rem 0;
    white-space: nowrap;
    width: 100%;
    display: block;
  }
  .flexbox {
    display: grid;
    align-items: center;
    grid-column-gap: 0.4375rem;
    grid-template-columns: 1.125rem 1.125rem 1fr 1.125rem;
  }
  .rootItems {
    display: inline-block;
    min-width: calc(100% - 4px);
    height: 100%;
  }
  .children {
    padding-left: 1.65rem;
    display: block;
  }
  .expando {
    grid-column-start: 1;
  }
  .checkbox {
    grid-column-start: 2;
    height: 1.125rem;
    width: 1.125rem;
    box-sizing: border-box;
  }
  .text {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    grid-column-start: 3;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .info {
    padding: 0px;
    width: 1.125rem;
    height: 1.125rem;
    position: sticky;
    right: 0rem;
    background-color: transparent;
    grid-column-start: 4;
    margin: 0;
  }
}