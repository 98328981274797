@layer components {
  .large {
    height: 100%;
  }
  .hasError svg {
    fill: var(--ggl-item-destructive-bg) !important;
  }
  .reveal {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .reveal svg {
    margin-top: -1px;
  }
}