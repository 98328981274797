@layer components {
  .image_upload {
    min-width: 1rem;
    cursor: pointer;
  }
  .btnGuides {
    margin-right: auto;
  }
  .minWidth {
    min-width: 25rem;
  }
  .layout {
    background-color: black;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: var(--ggl-border-radius-input);
    overflow: hidden;
  }
}