/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .btn {
    font-size: var(--ggl-font-size);
    line-height: 1.25rem;
    color: var(--ggl-item-text);
    border-color: var(--ggl-item-text);
    border-bottom-color: var(--ggl-item-text);
    background-color: var(--ggl-item-bg);
    display: inline-block;
    height: var(--ggl-control-item-height);
    padding: 0 0.5em 0 0.5em;
    cursor: pointer;
    user-select: none;
    border-radius: var(--ggl-border-radius-input);
    border-width: 1.25px;
    border-style: solid;
    width: fit-content;
    font-weight: 500;
    position: relative;
  }
  .btn svg {
    fill: var(--ggl-item-text);
  }
  .btn:hover {
    color: var(--ggl-item-text);
    border-color: var(--ggl-item-text);
    border-bottom-color: var(--ggl-item-text);
    background-color: var(--ggl-item-hover-bg);
  }
  .btn:hover svg {
    fill: var(--ggl-item-text);
  }
  .btn:active, .btn.active, .btn.loading {
    color: var(--ggl-item-active-text);
    border-color: var(--ggl-item-active-border);
    border-top-color: var(--ggl-item-active-border-tint);
    background-color: var(--ggl-item-active-bg);
  }
  .btn:active svg, .btn.active svg, .btn.loading svg {
    fill: var(--ggl-item-active-text);
  }
  .btn + span {
    white-space: nowrap;
  }
  .btn.skeleton {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
  }
  .btn.skeleton :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .btn.skeleton * {
    visibility: hidden !important;
  }
  .btn.skeleton::after {
    display: none;
  }
  .btn .children {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .btn .children .label {
    margin-top: 0.175rem;
  }
  .tiny {
    height: 1.125rem;
  }
  .tiny .children {
    font-size: 10px;
    line-height: 0.85rem;
  }
  .small {
    height: 1.5rem;
  }
  .small .children {
    font-size: 12px;
    line-height: 1rem;
  }
  .large {
    height: 2.625rem;
    font-size: 16px;
  }
  .x-large {
    height: 4rem;
    font-size: 16px;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    line-height: calc(2em - 2px);
  }
  .text {
    display: flex;
    align-items: center;
  }
  .loading .text {
    opacity: 0;
  }
  .loading_panel {
    margin: 0;
    position: absolute;
    padding-top: 0.125rem;
  }
  .loading_icon {
    width: 0.85em;
    height: 0.85em;
  }
  .rt_icon,
  .lt_icon {
    width: 1.5em;
    height: 1.5em;
  }
  .infoBtn {
    border-radius: 50%;
  }
  .infoBtn {
    padding: 0;
    width: 2em;
    overflow: hidden;
    min-width: 0;
  }
  .infoBtn.children {
    color: transparent;
    display: none;
  }
  .primary {
    color: var(--ggl-item-primary-text);
    border-color: var(--ggl-item-primary-border);
    border-bottom-color: var(--ggl-item-primary-border-tint);
    background-color: var(--ggl-item-primary-bg);
  }
  .primary svg {
    fill: var(--ggl-item-primary-text);
  }
  .primary:hover {
    color: var(--ggl-item-primary-hover-text);
    border-color: var(--ggl-item-primary-hover-border);
    border-bottom-color: var(--ggl-item-primary-hover-border-tint);
    background-color: var(--ggl-item-primary-hover-bg);
  }
  .primary:hover svg {
    fill: var(--ggl-item-primary-hover-text);
  }
  .primary:active, .primary.active, .primary.loading {
    color: var(--ggl-item-primary-active-text);
    border-color: var(--ggl-item-primary-active-border);
    border-top-color: var(--ggl-item-primary-active-border-tint);
    background-color: var(--ggl-item-primary-active-bg);
  }
  .primary:active svg, .primary.active svg, .primary.loading svg {
    fill: var(--ggl-item-primary-active-text);
  }
  .secondary {
    color: var(--ggl-item-secondary-text);
    border-color: var(--ggl-item-secondary-border);
    border-bottom-color: var(--ggl-item-secondary-border-tint);
    background-color: var(--ggl-item-secondary-bg);
  }
  .secondary svg {
    fill: var(--ggl-item-secondary-text);
  }
  .secondary:hover {
    color: var(--ggl-item-secondary-hover-text);
    border-color: var(--ggl-item-secondary-hover-border);
    border-bottom-color: var(--ggl-item-secondary-hover-border-tint);
    background-color: var(--ggl-item-secondary-hover-bg);
  }
  .secondary:hover svg {
    fill: var(--ggl-item-secondary-hover-text);
  }
  .secondary:active, .secondary.active, .secondary.loading {
    color: var(--ggl-item-secondary-active-text);
    border-color: var(--ggl-item-secondary-active-border);
    border-top-color: var(--ggl-item-secondary-active-border-tint);
    background-color: var(--ggl-item-secondary-active-bg);
  }
  .secondary:active svg, .secondary.active svg, .secondary.loading svg {
    fill: var(--ggl-item-secondary-active-text);
  }
  .attention {
    color: var(--ggl-item-attention-text);
    border-color: var(--ggl-item-attention-border);
    border-bottom-color: var(--ggl-item-attention-border-tint);
    background-color: var(--ggl-item-attention-bg);
  }
  .attention svg {
    fill: var(--ggl-item-attention-text);
  }
  .attention:hover {
    color: var(--ggl-item-attention-hover-text);
    border-color: var(--ggl-item-attention-hover-border);
    border-bottom-color: var(--ggl-item-attention-hover-border-tint);
    background-color: var(--ggl-item-attention-hover-bg);
  }
  .attention:hover svg {
    fill: var(--ggl-item-attention-hover-text);
  }
  .attention:active, .attention.active, .attention.loading {
    color: var(--ggl-item-attention-active-text);
    border-color: var(--ggl-item-attention-active-border);
    border-top-color: var(--ggl-item-attention-active-border-tint);
    background-color: var(--ggl-item-attention-active-bg);
  }
  .attention:active svg, .attention.active svg, .attention.loading svg {
    fill: var(--ggl-item-attention-active-text);
  }
  .destructive {
    color: var(--ggl-item-destructive-text);
    border-color: var(--ggl-item-destructive-border);
    border-bottom-color: var(--ggl-item-destructive-border);
    background-color: var(--ggl-item-destructive-bg);
  }
  .destructive svg {
    fill: var(--ggl-item-destructive-text);
  }
  .destructive:hover {
    color: var(--ggl-item-destructive-hover-text);
    border-color: var(--ggl-item-destructive-hover-border);
    border-bottom-color: var(--ggl-item-destructive-hover-border);
    background-color: var(--ggl-item-destructive-hover-bg);
  }
  .destructive:hover svg {
    fill: var(--ggl-item-destructive-hover-text);
  }
  .destructive:active, .destructive.active, .destructive.loading {
    color: var(--ggl-item-destructive-active-text);
    border-color: var(--ggl-item-destructive-active-border);
    border-top-color: var(--ggl-item-destructive-active-border);
    background-color: var(--ggl-item-destructive-active-bg);
  }
  .destructive:active svg, .destructive.active svg, .destructive.loading svg {
    fill: var(--ggl-item-destructive-active-text);
  }
  .none {
    color: var(--ggl-text);
    border-color: var(--ggl-control-border-light);
    border-bottom-color: var(--ggl-control-border-light);
    background-color: transparent;
  }
  .none svg {
    fill: var(--ggl-text);
  }
  .none:hover {
    color: var(--ggl-text);
    border-color: var(--ggl-control-border-light);
    border-bottom-color: var(--ggl-control-border-light);
    background-color: transparent;
  }
  .none:hover svg {
    fill: var(--ggl-text);
  }
  .none:active, .none.active, .none.loading {
    color: var(--ggl-text);
    border-color: var(--ggl-control-border);
    border-top-color: var(--ggl-control-border);
    background-color: transparent;
  }
  .none:active svg, .none.active svg, .none.loading svg {
    fill: var(--ggl-text);
  }
  .link {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: var(--ggl-item-primary-bg);
  }
  .link svg {
    fill: var(--ggl-item-primary-bg);
  }
  .link:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--ggl-item-primary-bg);
  }
  .link:hover svg {
    fill: var(--ggl-item-primary-bg);
  }
  .link:active {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--ggl-item-primary-bg);
  }
  .link:active svg {
    fill: var(--ggl-item-primary-bg);
  }
  .link[disabled] {
    background-color: transparent !important;
  }
  .link.attention {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: var(--ggl-item-attention-bg);
  }
  .link.attention svg {
    fill: var(--ggl-item-attention-bg);
  }
  .link.attention:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--ggl-item-attention-bg);
  }
  .link.attention:hover svg {
    fill: var(--ggl-item-attention-bg);
  }
  .link.attention:active {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--ggl-item-attention-bg);
  }
  .link.attention:active svg {
    fill: var(--ggl-item-attention-bg);
  }
  .link.attention[disabled] {
    background-color: transparent !important;
  }
  .link.destructive {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: var(--ggl-item-destructive-bg);
  }
  .link.destructive svg {
    fill: var(--ggl-item-destructive-bg);
  }
  .link.destructive:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--ggl-item-destructive-bg);
  }
  .link.destructive:hover svg {
    fill: var(--ggl-item-destructive-bg);
  }
  .link.destructive:active {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--ggl-item-destructive-bg);
  }
  .link.destructive:active svg {
    fill: var(--ggl-item-destructive-bg);
  }
  .link.destructive[disabled] {
    background-color: transparent !important;
  }
  .link.none {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: var(--ggl-text);
  }
  .link.none svg {
    fill: var(--ggl-text);
  }
  .link.none:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--ggl-text);
  }
  .link.none:hover svg {
    fill: var(--ggl-text);
  }
  .link.none:active {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--ggl-text);
  }
  .link.none:active svg {
    fill: var(--ggl-text);
  }
  .link.none[disabled] {
    background-color: transparent !important;
  }
  .input {
    color: var(--ggl-text);
    border-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
  }
  .input svg {
    fill: var(--ggl-text);
  }
  .input:hover {
    color: var(--ggl-hover-text);
    border-color: transparent;
    border-bottom-color: transparent;
    background-color: var(--ggl-hover-bg);
  }
  .input:hover svg {
    fill: var(--ggl-hover-text);
  }
  .input:active, .input.active, .input.loading {
    color: var(--ggl-active-text);
    border-color: transparent;
    border-top-color: transparent;
    background-color: var(--ggl-active-bg);
  }
  .input:active svg, .input.active svg, .input.loading svg {
    fill: var(--ggl-active-text);
  }
  /* disabled */
  .btn[disabled],
  .btn[disabled]:hover,
  .btn[disabled]:active,
  .btn.disabled,
  .btn.disabled:hover,
  .btn.disabled:active {
    color: var(--ggl-item-disabled-text);
    border-color: var(--ggl-item-disabled-border);
    background-color: var(--ggl-item-disabled-bg);
  }
  .btn[disabled] svg,
  .btn[disabled]:hover svg,
  .btn[disabled]:active svg,
  .btn.disabled svg,
  .btn.disabled:hover svg,
  .btn.disabled:active svg {
    fill: var(--ggl-item-disabled-text);
  }
  .fullWidth {
    width: 100% !important;
  }
  .bounce {
    animation-name: bounce;
    animation-delay: 250ms;
    animation-duration: 1000ms;
    animation-timing-function: ease-in-out;
  }
  @keyframes bounce {
    0% {
      transform: scale(1, 1) translateY(0);
    }
    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
      transform: scale(0.9, 1.1) translateY(-10px);
    }
    50% {
      transform: scale(1, 1) translateY(0);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
}