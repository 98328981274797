/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .fakeInput {
    display: flex;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    color: var(--ggl-text-color);
    flex-grow: 1;
    background-color: transparent;
    border: none;
    outline: transparent;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    font-size: var(--ggl-font-size);
    line-height: 1.25rem;
    height: 2rem;
    line-height: 2rem;
  }
  .primary {
    color: var(--ggl-item-primary-text);
    border-color: var(--ggl-item-primary-border);
    border-bottom-color: var(--ggl-item-primary-border-tint);
    background-color: var(--ggl-item-primary-bg);
  }
  .primary svg {
    fill: var(--ggl-item-primary-text);
  }
  .primary:hover {
    color: var(--ggl-item-primary-hover-text);
    border-color: var(--ggl-item-primary-hover-border);
    border-bottom-color: var(--ggl-item-primary-hover-border-tint);
    background-color: var(--ggl-item-primary-hover-bg);
  }
  .primary:hover svg {
    fill: var(--ggl-item-primary-hover-text);
  }
  .primary:active, .primary.active, .primary.loading {
    color: var(--ggl-item-primary-active-text);
    border-color: var(--ggl-item-primary-active-border);
    border-top-color: var(--ggl-item-primary-active-border-tint);
    background-color: var(--ggl-item-primary-active-bg);
  }
  .primary:active svg, .primary.active svg, .primary.loading svg {
    fill: var(--ggl-item-primary-active-text);
  }
  .secondary {
    color: var(--ggl-item-secondary-text);
    border-color: var(--ggl-item-secondary-border);
    border-bottom-color: var(--ggl-item-secondary-border-tint);
    background-color: var(--ggl-item-secondary-bg);
  }
  .secondary svg {
    fill: var(--ggl-item-secondary-text);
  }
  .secondary:hover {
    color: var(--ggl-item-secondary-hover-text);
    border-color: var(--ggl-item-secondary-hover-border);
    border-bottom-color: var(--ggl-item-secondary-hover-border-tint);
    background-color: var(--ggl-item-secondary-hover-bg);
  }
  .secondary:hover svg {
    fill: var(--ggl-item-secondary-hover-text);
  }
  .secondary:active, .secondary.active, .secondary.loading {
    color: var(--ggl-item-secondary-active-text);
    border-color: var(--ggl-item-secondary-active-border);
    border-top-color: var(--ggl-item-secondary-active-border-tint);
    background-color: var(--ggl-item-secondary-active-bg);
  }
  .secondary:active svg, .secondary.active svg, .secondary.loading svg {
    fill: var(--ggl-item-secondary-active-text);
  }
  .attention {
    color: var(--ggl-item-attention-text);
    border-color: var(--ggl-item-attention-border);
    border-bottom-color: var(--ggl-item-attention-border-tint);
    background-color: var(--ggl-item-attention-bg);
  }
  .attention svg {
    fill: var(--ggl-item-attention-text);
  }
  .attention:hover {
    color: var(--ggl-item-attention-hover-text);
    border-color: var(--ggl-item-attention-hover-border);
    border-bottom-color: var(--ggl-item-attention-hover-border-tint);
    background-color: var(--ggl-item-attention-hover-bg);
  }
  .attention:hover svg {
    fill: var(--ggl-item-attention-hover-text);
  }
  .attention:active, .attention.active, .attention.loading {
    color: var(--ggl-item-attention-active-text);
    border-color: var(--ggl-item-attention-active-border);
    border-top-color: var(--ggl-item-attention-active-border-tint);
    background-color: var(--ggl-item-attention-active-bg);
  }
  .attention:active svg, .attention.active svg, .attention.loading svg {
    fill: var(--ggl-item-attention-active-text);
  }
  .destructive {
    color: var(--ggl-item-destructive-text);
    border-color: var(--ggl-item-destructive-border);
    border-bottom-color: var(--ggl-item-destructive-border);
    background-color: var(--ggl-item-destructive-bg);
  }
  .destructive svg {
    fill: var(--ggl-item-destructive-text);
  }
  .destructive:hover {
    color: var(--ggl-item-destructive-hover-text);
    border-color: var(--ggl-item-destructive-hover-border);
    border-bottom-color: var(--ggl-item-destructive-hover-border);
    background-color: var(--ggl-item-destructive-hover-bg);
  }
  .destructive:hover svg {
    fill: var(--ggl-item-destructive-hover-text);
  }
  .destructive:active, .destructive.active, .destructive.loading {
    color: var(--ggl-item-destructive-active-text);
    border-color: var(--ggl-item-destructive-active-border);
    border-top-color: var(--ggl-item-destructive-active-border);
    background-color: var(--ggl-item-destructive-active-bg);
  }
  .destructive:active svg, .destructive.active svg, .destructive.loading svg {
    fill: var(--ggl-item-destructive-active-text);
  }
  .chip {
    margin-top: -0.125rem;
  }
  .overflow {
    width: 100%;
    overflow: hidden;
  }
  .item {
    align-items: center;
    line-height: 2rem;
    outline: none;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }
  .inlineIcon {
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.4rem;
  }
  .placeholder {
    color: var(--ggl-placeholder) !important;
    outline: none;
  }
}