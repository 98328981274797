/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes placeholderShimmer {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes skeleton {
  0% {
    opacity: 0.15;
  }
  50% {
    opacity: 0.35;
  }
  100% {
    opacity: 0.15;
  }
}
.fadeIn {
  animation-timing-function: linear;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.fadeOut {
  animation-timing-function: linear;
  animation-name: fadeOut;
  animation-duration: 0.5s;
}

.root {
  max-height: calc(100vh - 1rem);
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  border: none;
  background-color: transparent;
  overflow: hidden;
  height: fit-content;
  width: fit-content;
  z-index: 2;
}
.root::backdrop {
  background-color: transparent;
}

.opaque::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.fallBack {
  position: fixed;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  transform-style: preserve-3d;
}

.fallBackModal {
  box-shadow: var(--ggl-drop-shadow-high);
  z-index: 1;
  position: relative;
  padding: 0;
  width: fit-content;
  height: fit-content;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 12.5rem;
}

.backdrop {
  z-index: 1;
}

.modal {
  box-shadow: var(--ggl-drop-shadow-high);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 2rem 1fr 3.5rem;
  grid-template-areas: "header" "body" "footer";
  max-height: calc(100vh - 4rem);
  max-width: calc(100vw - 4rem);
  padding: 1.5rem !important;
  min-width: 12.5rem;
}

.header {
  display: flex;
  grid-area: header;
  margin: 0;
  height: 1.875rem;
  padding-bottom: 0.875rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: thin solid var(--ggl-disabled-bg);
}
.header h2 {
  font-size: 1rem;
  margin: 0;
}

.body {
  margin-top: 1rem;
  color: var(--ggl-text);
  grid-area: body;
}

footer {
  align-self: end;
}

@media only screen and (max-width: 30rem) {
  .modal {
    max-height: calc(100vh - 10rem);
  }
}
@media only screen and (max-height: 43.75rem) {
  .body {
    overflow: auto;
    overflow-x: hidden;
  }
}