/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    display: flex;
    width: 100%;
    gap: 0.5rem;
    flex-direction: column;
  }
  .title {
    font-size: 12px;
    line-height: 1rem;
    width: 100%;
    text-align: center;
  }
  .controls {
    width: 100%;
    justify-content: space-between;
    display: flex;
  }
  .controls button {
    flex-basis: calc(50% - 0.5rem);
  }
  .viewBox {
    height: 11.75rem;
    overflow: hidden;
    position: relative;
    border-radius: var(--ggl-border-radius-input);
    justify-content: center;
    display: flex;
    margin-bottom: 0.5rem;
    background-color: var(--ggl-control-bg);
    border: thin solid var(--ggl-control-border-light);
  }
  .viewBox.hasImage {
    background-color: black;
    border: none;
  }
  .image {
    display: block;
    max-height: 100%;
    width: auto;
    height: auto;
    min-height: 50%;
  }
  .error {
    color: var(--ggl-msg-destructive-text);
    background-color: var(--ggl-msg-destructive-bg);
    position: absolute;
    top: 1rem;
    margin: 0;
  }
  .slide {
    width: auto;
    height: auto;
    opacity: 0;
    transition-duration: 0s;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .slide_active {
    width: 100%;
    height: 100%;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0s;
    transform: scale(1);
    position: absolute;
  }
  .nav {
    position: absolute;
    width: 2rem;
    height: 100%;
    display: flex;
    place-content: center;
    cursor: pointer;
    user-select: none;
  }
  .nav svg {
    fill: rgb(163, 163, 163);
  }
  .nav:hover {
    background-color: rgba(135, 135, 135, 0.5);
  }
  .nav:active {
    background-color: rgba(135, 135, 135, 0.65);
  }
  .forward {
    right: 0;
  }
  .back {
    left: 0;
  }
  .dotBox {
    justify-content: center;
    align-items: center;
    background-color: black;
    position: absolute;
    border-radius: var(--ggl-border-radius-input);
    padding: 0.25rem;
    display: flex;
    bottom: 1rem;
    column-gap: 0.25rem;
  }
  .dot {
    width: 0.5rem;
    height: 0.5rem;
    background-color: white;
    opacity: 50%;
    border-radius: 50%;
    cursor: pointer;
  }
  .dot_active {
    opacity: 100%;
  }
  .dot_required {
    color: var(--ggl-control-error);
  }
  .dot_required::before {
    content: "*";
    position: relative;
    top: -6px;
    left: 1px;
  }
  .dot_error {
    background-color: var(--ggl-text-destructive-accent);
    color: var(--ggl-item-destructive-text);
  }
}