$brand: "ggl";
.viewport {
  width: 100%;
  height: 100%;
  background: var(--ggl-body-bg);
}

.breadcrumbWrapper {
  position: fixed;
  top: 3.5rem;
  z-index: 498;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: var(--ggl-breadcrumb-bar);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);

  > * {
    width: 100%;
    max-width: var(--page-max-width);
  }
}

.drawerWrapper {
  z-index: 590; // below banner
}

@media only screen and (min-width: 860px) {
  .breadcrumbWrapper {
    padding-left: 3.5rem;
  }
}
