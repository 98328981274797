@use "sass:list";
@use "variables" as *;
@import "functions";

@mixin themes($mode) {
  --ggl-label: #{nth($text, $mode)};
  --ggl-text: #{nth($text, $mode)};
  --ggl-brand: #{$brand-color};
  --ggl-border-radius-input: #{$border-radius-inputs};
  --ggl-border-radius-panel: #{$border-radius-panels};
  --ggl-border-radius-checkbox: #{$border-radius-checkbox};
  --ggl-loading-bg: #{$loading-bg};
  --ggl-placeholder: #{nth($placeholder, $mode)};
  --ggl-backdrop: #{nth($backdrop, $mode)};
  --ggl-body-bg: #{nth($body-bg, $mode)};

  // Text
  --ggl-text-size: 100%;
  --ggl-link-color: #{nth($hyperlink, $mode)};
  --ggl-link-hover: #{nth($hyperlink-hover, $mode)};
  --ggl-link-visited: #{nth($hyperlink-visited, $mode)};
  --ggl-text-color: #{nth($text, $mode)};
  --ggl-text-color-primary: #{$B700};
  --ggl-text-color-success: #{$G700};
  --ggl-text-color-attention: #{$O700};
  --ggl-text-color-destructive: #{$R700};
  --ggl-text-faint: #{nth($text-faint, $mode)};
  --ggl-text-accent: #{nth($text-accent, $mode)};
  --ggl-text-disabled: #{nth($text-disabled, $mode)};
  --ggl-text-destructive: #{nth($text-destructive, $mode)};
  --ggl-font-size: #{$font-size};

  // Colors for when text has an inbuilt message or intention:
  --ggl-text-primary-color: #{nth($text-primary, $mode)};
  --ggl-text-primary-accent: #{nth($text-primary-accent, $mode)};
  --ggl-text-primary-disabled: #{nth($text-disabled, $mode)};

  --ggl-text-attention-color: #{nth($text-attention, $mode)};
  --ggl-text-attention-accent: #{nth($text-attention-accent, $mode)};
  --ggl-text-attention-disabled: #{nth($text-disabled, $mode)};

  --ggl-text-destructive-color: #{nth($text-destructive, $mode)};
  --ggl-text-destructive-accent: #{nth($text-destructive-accent, $mode)};
  --ggl-text-destructive-disabled: #{nth($text-disabled, $mode)};

  --ggl-text-success-light-bg: #{nth($text-success-light, $mode)};
  --ggl-text-success-color: #{nth($text-success, $mode)};
  --ggl-text-success-accent: #{nth($text-success-accent, $mode)};
  --ggl-text-success-disabled: #{nth($text-disabled, $mode)};

  // Headings
  // --ggl-label: #{$N800};
  --ggl-menu-heading: #{$B400};
  --ggl-table-heading-bg: #{$tableheader-bg};

  // forms
  --ggl-form-bg: #{nth($form-bg, $mode)};
  --ggl-form-border: #{nth($form-border, $mode)};
  --ggl-form-row-gap: #{rem($form-row-gap)};
  --ggl-form-col-gap: #{rem($form-col-gap)};
  --ggl-form-padding: #{$form-padding};

  // controls
  --ggl-control-bg: #{nth($control-bg, $mode)};
  --ggl-control-light-bg: #{nth($control-bg-light, $mode)};
  --ggl-control-bg-error: #{nth($control-bg-error, $mode)};
  --ggl-control-error: #{nth($control-error, $mode)};
  --ggl-control-item-height: #{rem($control-item-height)};
  --ggl-control-border: #{nth($control-border, $mode)};
  --ggl-control-border-hover: #{nth($control-border-hover, $mode)};
  --ggl-control-border-light: #{nth($control-border-light, $mode)};
  --ggl-control-border-error: #{nth($control-border-error, $mode)};
  --ggl-control-button-hover: #{nth($control-button-hover, $mode)};
  --ggl-control-border-active: #{nth($control-border-active, $mode)};

  --ggl-control-button: #{nth($control-button, $mode)};
  --ggl-control-button-active: #{nth($control-button-active, $mode)};
  --ggl-control-collapsed: #{nth($control-button-collapsed, $mode)};

  --ggl-control-checkbox-border: #{nth($control-checkbox-border, $mode)};
  --ggl-control-checkbox-box-shadow: #{nth($control-checkbox-box-shadow, $mode)};
  --ggl-control-checkbox-box-shadow-checked: #{nth($control-checkbox-box-shadow-checked, $mode)} #{var(
      --ggl-item-primary-bg
    )};
  --ggl-control-checkbox-readonly-border: #{nth($control-border-light, $mode)};
  --ggl-control-checkbox-readonly-selected: #{nth($item-active-bg, $mode)};

  // Blocks & panels
  --ggl-panel-bg: #{nth($panel-bg, $mode)};
  --ggl-panel-bg-alt: #{nth($panel-bg-alt, $mode)};
  --ggl-panel-border: #{nth($panel-border, $mode)};
  --ggl-panel-border-alt: #{nth($panel-border-alt, $mode)};
  --ggl-disabled-bg: #{nth($disabled-bg, $mode)};

  --ggl-border: #{nth($border-color, $mode)};
  --ggl-border-disabled: #{nth($disabled-border, $mode)};
  --ggl-border-readonly: #{nth($readonly-border, $mode)};
  --ggl-border-readonly-focus: #{nth($control-border-light, $mode)};

  --ggl-drop-shadow-low: #{nth($drop-shadow-low, $mode)};
  --ggl-drop-shadow-inset: #{nth($drop-shadow-inset, $mode)};
  --ggl-drop-shadow-medium: #{nth($drop-shadow-medium, $mode)};
  --ggl-drop-shadow-high: #{nth($drop-shadow-high, $mode)};

  // Default block
  --ggl-item-bg: #{nth($item-bg, $mode)};
  --ggl-item-text: #{nth($item-text, $mode)};
  --ggl-item-border: #{nth($item-border, $mode)};
  --ggl-item-border-tint: #{nth($item-border, $mode)};

  --ggl-item-hover-bg: #{nth($item-hover-bg, $mode)};
  --ggl-item-hover-text: #{nth($item-hover-text, $mode)};
  --ggl-item-hover-border: #{nth($item-hover-border, $mode)};
  --ggl-item-hover-border-tint: #{nth($item-hover-border, $mode)};

  --ggl-item-active-bg: #{nth($item-active-bg, $mode)};
  --ggl-item-active-text: #{nth($item-active-text, $mode)};
  --ggl-item-active-border: #{nth($item-active-border, $mode)};
  --ggl-item-active-border-tint: #{nth($item-active-border, $mode)};

  // disabled interactive items
  --ggl-item-disabled-bg: #{nth($item-disabled-bg, $mode)};
  --ggl-item-disabled-text: #{nth($item-disabled-text, $mode)};
  --ggl-item-disabled-border: #{nth($item-disabled-border, $mode)};

  // Primary block
  --ggl-item-primary-bg: #{nth($item-primary-bg, $mode)};
  --ggl-item-primary-text: #{nth($item-primary-text, $mode)};
  --ggl-item-primary-border: #{nth($item-primary-border, $mode)};
  --ggl-item-primary-border-tint: #{nth($item-primary-border, $mode)};

  --ggl-item-primary-hover-bg: #{nth($item-primary-hover-bg, $mode)};
  --ggl-item-primary-hover-text: #{nth($item-primary-hover-text, $mode)};
  --ggl-item-primary-hover-border: #{nth($item-primary-hover-border, $mode)};
  --ggl-item-primary-hover-border-tint: #{nth($item-primary-hover-border, $mode)};

  --ggl-item-primary-active-bg: #{nth($item-primary-active-bg, $mode)};
  --ggl-item-primary-active-text: #{nth($item-primary-active-text, $mode)};
  --ggl-item-primary-active-border: #{nth($item-primary-active-border, $mode)};
  --ggl-item-primary-active-border-tint: #{nth($item-primary-active-border, $mode)};

  --ggl-item-primary-command-bg: #{nth($item-primary-command-bg, $mode)};

  // Attention block
  --ggl-item-attention-bg: #{nth($item-attention-bg, $mode)};
  --ggl-item-attention-text: #{nth($item-attention-text, $mode)};
  --ggl-item-attention-border: #{nth($item-attention-border, $mode)};
  --ggl-item-attention-border-tint: #{nth($item-attention-border, $mode)};

  --ggl-item-attention-hover-bg: #{nth($item-attention-hover-bg, $mode)};
  --ggl-item-attention-hover-text: #{nth($item-attention-hover-text, $mode)};
  --ggl-item-attention-hover-border: #{nth($item-attention-hover-border, $mode)};
  --ggl-item-attention-hover-border-tint: #{nth($item-attention-hover-border, $mode)};

  --ggl-item-attention-active-bg: #{nth($item-attention-active-bg, $mode)};
  --ggl-item-attention-active-text: #{nth($item-attention-active-text, $mode)};
  --ggl-item-attention-active-border: #{nth($item-attention-active-border, $mode)};
  --ggl-item-attention-active-border-tint: #{nth($item-attention-active-border, $mode)};

  --ggl-item-attention-command-bg: #{nth($item-attention-command-bg, $mode)};

  // Destructive block
  --ggl-item-destructive-bg: #{nth($item-destructive-bg, $mode)};
  --ggl-item-destructive-text: #{nth($item-destructive-text, $mode)};
  --ggl-item-destructive-border: #{nth($item-destructive-border, $mode)};
  --ggl-item-destructive-border-tint: #{nth($item-destructive-border, $mode)};

  --ggl-item-destructive-hover-bg: #{nth($item-destructive-hover-bg, $mode)};
  --ggl-item-destructive-hover-text: #{nth($item-destructive-hover-text, $mode)};
  --ggl-item-destructive-hover-border: #{nth($item-destructive-hover-border, $mode)};
  --ggl-item-destructive-hover-border-tint: #{nth($item-destructive-hover-border, $mode)};

  --ggl-item-destructive-active-bg: #{nth($item-destructive-active-bg, $mode)};
  --ggl-item-destructive-active-text: #{nth($item-destructive-active-text, $mode)};
  --ggl-item-destructive-active-border: #{nth($item-destructive-active-border, $mode)};
  --ggl-item-destructive-active-border-tint: #{nth($item-destructive-active-border, $mode)};

  --ggl-item-destructive-command-bg: #{nth($item-destructive-command-bg, $mode)};

  // Success block
  --ggl-item-success-bg: #{nth($item-success-bg, $mode)};
  --ggl-item-success-text: #{nth($item-success-text, $mode)};
  --ggl-item-success-border: #{nth($item-success-border, $mode)};
  --ggl-item-success-border-tint: #{nth($item-success-border, $mode)};

  --ggl-item-success-hover-bg: #{nth($item-success-hover-bg, $mode)};
  --ggl-item-success-hover-text: #{nth($item-success-hover-text, $mode)};
  --ggl-item-success-hover-border: #{nth($item-success-hover-border, $mode)};
  --ggl-item-success-hover-border-tint: #{nth($item-success-hover-border, $mode)};

  --ggl-item-success-active-bg: #{nth($item-success-active-bg, $mode)};
  --ggl-item-success-active-text: #{nth($item-success-active-text, $mode)};
  --ggl-item-success-active-border: #{nth($item-success-active-border, $mode)};
  --ggl-item-success-active-border-tint: #{nth($item-success-active-border, $mode)};

  --ggl-item-success-command-bg: #{nth($item-success-command-bg, $mode)};

  // Secondary block
  --ggl-item-secondary-bg: #{nth($item-command-bg, $mode)};
  --ggl-item-secondary-text: #{nth($item-command-text, $mode)};
  --ggl-item-secondary-border: #{nth($item-command-border, $mode)};
  --ggl-item-secondary-border-tint: #{nth($item-command-border, $mode)};

  --ggl-item-secondary-hover-bg: #{nth($item-command-hover-bg, $mode)};
  --ggl-item-secondary-hover-text: #{nth($item-command-hover-text, $mode)};
  --ggl-item-secondary-hover-border: #{nth($item-command-hover-border, $mode)};
  --ggl-item-secondary-hover-border-tint: #{nth($item-command-hover-border, $mode)};

  --ggl-item-secondary-active-bg: #{nth($item-command-active-bg, $mode)};
  --ggl-item-secondary-active-text: #{nth($item-command-active-text, $mode)};
  --ggl-item-secondary-active-border: #{nth($item-command-active-border, $mode)};
  --ggl-item-secondary-active-border-tint: #{nth($item-command-active-border, $mode)};

  // message panel - primary
  --ggl-msg-primary-bg: #{nth($msg-primary-bg, $mode)};
  --ggl-msg-primary-text: #{nth($msg-primary-text, $mode)};
  --ggl-msg-primary-border: #{nth($msg-primary-border, $mode)};

  // message panel - success
  --ggl-msg-success-bg: #{nth($msg-success-bg, $mode)};
  --ggl-msg-success-text: #{nth($msg-success-text, $mode)};
  --ggl-msg-success-border: #{nth($msg-success-border, $mode)};

  // message panel - destructive
  --ggl-msg-destructive-bg: #{nth($msg-destructive-bg, $mode)};
  --ggl-msg-destructive-text: #{nth($msg-destructive-text, $mode)};
  --ggl-msg-destructive-border: #{nth($msg-destructive-border, $mode)};

  // message panel - success
  --ggl-msg-attention-bg: #{nth($msg-attention-bg, $mode)};
  --ggl-msg-attention-text: #{nth($msg-attention-text, $mode)};
  --ggl-msg-attention-border: #{nth($msg-attention-border, $mode)};
  --ggl-calendar-icon: #{nth($calendar-icon, $mode)};
}

:root {
  @include themes(1);
}

[data-theme="dark"] {
  @include themes(2);
}
