$brand: "ggl";
.root {
  grid-area: main;
  text-align: center;
  opacity: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 3.25rem;
}

.logo {
  width: 15.5rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
}
.logo figure {
  width: 12rem;
}

.card {
  width: 24rem;
  padding: 2.5rem;
  background-color: var(--ggl-form-bg);
}

.help {
  text-decoration: none;
  color: var(--ggl-link-color);
  display: block;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.welcomeContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
}
.welcome {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
}
.signIn {
  text-align: center;
}

.mfaContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.mfaTitle {
  display: block;
  font-size: 1.5rem;
  color: var(--ggl-text-color);
}

.mfaInstructions {
  font-size: 0.75rem; // 12px
  line-height: 1.25rem;
}

.mfaInputs {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
}

.mfaInput {
  width: 2.125rem;
  height: 2.813rem;
  text-align: center;
  border: 1px solid var(--ggl-control-border-light);
  background-color: var(--ggl-control-bg);
  color: var(--ggl-text-accent);
  border-radius: 0.25rem;
  font-size: 2rem;
  font-weight: 600;
  caret-color: transparent;
  padding: 0;
  &.gap {
    margin-right: 0.75rem;
  }
  &:focus {
    border: none;
    outline: 1.25px solid var(--ggl-brand);
  }
}
.messageBar {
  align-items: center;
  line-height: 1;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.btnRow {
  margin-top: 0.5rem;
  margin-bottom: 0rem;
}

.version {
  padding-top: 0.5rem;
  color: var(--ggl-text-primary-disabled);
}

@media only screen and (max-width: 560px) {
  .card {
    width: fit-content;
    overflow: initial;
  }
}
