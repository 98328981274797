$brand: "ggl";
.root {
  grid-area: main;
  text-align: center;
  opacity: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 3.25rem;
}
