$brand: "ggl";
@import "css-variables";

* {
    box-sizing: border-box;
    font-family: "Segoe UI Variable", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, Ubuntu, Helvetica, Arial,
        sans-serif;
}

html,
body {
    height: 100%;
    height: 100vh;
    color: var(--ggl-text-color);
    background-color: var(--ggl-body-bg);
    line-height: 1.5rem;
}

html,
head,
body,
#root,
figure {
    margin: 0;
    padding: 0;
    border: 0;
}

#__next {
    height: 100%;
    z-index: 0;
    position: relative;
}
