/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .hitTarget {
    font-size: 14px;
    font-size: var(--ggl-font-size);
    line-height: 1.25rem;
    color: var(--ggl-item-text);
    border-color: var(--ggl-item-text);
    border-bottom-color: var(--ggl-item-text);
    background-color: var(--ggl-item-bg);
    display: inline-block;
    height: var(--ggl-control-item-height);
    padding: 0 0.5em 0 0.5em;
    cursor: pointer;
    user-select: none;
    border-radius: var(--ggl-border-radius-input);
    border-width: 1.25px;
    border-style: solid;
    width: fit-content;
    font-weight: 500;
    position: relative;
    color: var(--ggl-text-color);
    border-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.25rem;
  }
  .hitTarget svg {
    fill: var(--ggl-item-text);
  }
  .hitTarget:hover {
    color: var(--ggl-item-text);
    border-color: var(--ggl-item-text);
    border-bottom-color: var(--ggl-item-text);
    background-color: var(--ggl-item-hover-bg);
  }
  .hitTarget:hover svg {
    fill: var(--ggl-item-text);
  }
  .hitTarget:active, .hitTarget.active, .hitTarget.loading {
    color: var(--ggl-item-active-text);
    border-color: var(--ggl-item-active-border);
    border-top-color: var(--ggl-item-active-border-tint);
    background-color: var(--ggl-item-active-bg);
  }
  .hitTarget:active svg, .hitTarget.active svg, .hitTarget.loading svg {
    fill: var(--ggl-item-active-text);
  }
  .hitTarget + span {
    white-space: nowrap;
  }
  .hitTarget.skeleton {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
  }
  .hitTarget.skeleton :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .hitTarget.skeleton * {
    visibility: hidden !important;
  }
  .hitTarget.skeleton::after {
    display: none;
  }
  .hitTarget svg {
    fill: var(--ggl-text-color);
  }
  .hitTarget:hover {
    color: var(--ggl-text-color);
    border-color: transparent;
    border-bottom-color: transparent;
    background-color: var(--ggl-control-button-hover);
  }
  .hitTarget:hover svg {
    fill: var(--ggl-text-color);
  }
  .hitTarget:active, .hitTarget.active, .hitTarget.loading {
    color: var(--ggl-text-color);
    border-color: transparent;
    border-top-color: transparent;
    background-color: var(--ggl-control-border);
  }
  .hitTarget:active svg, .hitTarget.active svg, .hitTarget.loading svg {
    fill: var(--ggl-text-color);
  }
  .hitTarget span {
    line-height: 1.25rem;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .readonly {
    background-color: transparent !important;
  }
  .readonly:hover {
    background-color: transparent;
  }
  .item {
    margin: 0.5rem;
    height: var(--ggl-control-item-height);
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .item .hitTarget {
    width: 100%;
    justify-content: left;
    margin-right: 0;
  }
  .item .indent {
    padding-left: 1.75rem;
  }
  .hint {
    margin-left: auto;
  }
  .selected {
    background-color: var(--ggl-disabled-bg);
  }
  .icon {
    width: 0.75rem;
    height: 0.75rem;
    flex-shrink: 0;
  }
  .disabled {
    cursor: auto;
    opacity: 0.5;
  }
  .disabled:hover {
    background-color: transparent;
  }
  .text {
    padding-left: 0.25rem;
  }
  .margin {
    margin: 0.25rem;
  }
  .primary {
    color: var(--ggl-msg-primary-text);
    border-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
  }
  .primary svg {
    fill: var(--ggl-msg-primary-text);
  }
  .primary:hover {
    color: var(--ggl-msg-primary-text);
    border-color: transparent;
    border-bottom-color: transparent;
    background-color: var(--ggl-msg-primary-faint);
  }
  .primary:hover svg {
    fill: var(--ggl-msg-primary-text);
  }
  .primary:active, .primary.active, .primary.loading {
    color: var(--ggl-msg-primary-text);
    border-color: transparent;
    border-top-color: transparent;
    background-color: var(--ggl-msg-primary-bg);
  }
  .primary:active svg, .primary.active svg, .primary.loading svg {
    fill: var(--ggl-msg-primary-text);
  }
  .success {
    color: var(--ggl-msg-success-text);
    border-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
  }
  .success svg {
    fill: var(--ggl-msg-success-text);
  }
  .success:hover {
    color: var(--ggl-msg-success-text);
    border-color: transparent;
    border-bottom-color: transparent;
    background-color: var(--ggl-msg-success-faint);
  }
  .success:hover svg {
    fill: var(--ggl-msg-success-text);
  }
  .success:active, .success.active, .success.loading {
    color: var(--ggl-msg-success-text);
    border-color: transparent;
    border-top-color: transparent;
    background-color: var(--ggl-msg-success-bg);
  }
  .success:active svg, .success.active svg, .success.loading svg {
    fill: var(--ggl-msg-success-text);
  }
  .attention {
    color: var(--ggl-msg-attention-text);
    border-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
  }
  .attention svg {
    fill: var(--ggl-msg-attention-text);
  }
  .attention:hover {
    color: var(--ggl-msg-attention-text);
    border-color: transparent;
    border-bottom-color: transparent;
    background-color: var(--ggl-msg-attention-faint);
  }
  .attention:hover svg {
    fill: var(--ggl-msg-attention-text);
  }
  .attention:active, .attention.active, .attention.loading {
    color: var(--ggl-msg-attention-text);
    border-color: transparent;
    border-top-color: transparent;
    background-color: var(--ggl-msg-attention-bg);
  }
  .attention:active svg, .attention.active svg, .attention.loading svg {
    fill: var(--ggl-msg-attention-text);
  }
  .destructive {
    color: var(--ggl-msg-destructive-text);
    border-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
  }
  .destructive svg {
    fill: var(--ggl-msg-destructive-text);
  }
  .destructive:hover {
    color: var(--ggl-msg-destructive-text);
    border-color: transparent;
    border-bottom-color: transparent;
    background-color: var(--ggl-msg-destructive-faint);
  }
  .destructive:hover svg {
    fill: var(--ggl-msg-destructive-text);
  }
  .destructive:active, .destructive.active, .destructive.loading {
    color: var(--ggl-msg-destructive-text);
    border-color: transparent;
    border-top-color: transparent;
    background-color: var(--ggl-msg-destructive-bg);
  }
  .destructive:active svg, .destructive.active svg, .destructive.loading svg {
    fill: var(--ggl-msg-destructive-text);
  }
  .skeleton {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
  }
  .skeleton :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .skeleton * {
    visibility: hidden !important;
  }
  .skeleton::after {
    display: none;
  }
}