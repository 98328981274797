/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 2em 1fr;
    min-height: 2rem;
    grid-template-areas: ". .";
    border-radius: var(--ggl-border-radius-input);
    border-width: thin;
    border-style: solid;
    color: var(--ggl-msg-primary-text);
    border-color: var(--ggl-msg-primary-border);
    background-color: var(--ggl-msg-primary-bg);
  }
  .root svg {
    fill: var(--ggl-msg-primary-border);
  }
  .destructive {
    color: var(--ggl-msg-destructive-text);
    border-color: var(--ggl-msg-destructive-border);
    background-color: var(--ggl-msg-destructive-bg);
  }
  .destructive svg {
    fill: var(--ggl-msg-destructive-border);
  }
  .destructive:hover .dismiss {
    background-color: var(--ggl-item-destructive-hover-bg);
  }
  .attention {
    color: var(--ggl-msg-attention-text);
    border-color: var(--ggl-msg-attention-border);
    background-color: var(--ggl-msg-attention-bg);
  }
  .attention svg {
    fill: var(--ggl-msg-attention-border);
  }
  .attention:hover .dismiss {
    background-color: var(--ggl-item-attention-hover-bg);
  }
  .success {
    color: var(--ggl-msg-success-text);
    border-color: var(--ggl-msg-success-border);
    background-color: var(--ggl-msg-success-bg);
  }
  .success svg {
    fill: var(--ggl-msg-success-border);
  }
  .success:hover .dismiss {
    background-color: var(--ggl-item-success-hover-bg);
  }
  .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: auto;
  }
  .text {
    align-self: center;
    margin: 0;
    padding: 0.25rem 0.5rem 0.25rem 0;
    flex-grow: 1;
    line-height: 1rem;
  }
}