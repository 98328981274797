/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .card {
    box-shadow: var(--ggl-drop-shadow-low);
    padding: 1rem;
    background-color: var(--ggl-panel-bg);
    border-radius: var(--ggl-border-radius-panel);
    border: thin solid var(--ggl-panel-border-alt);
    overflow: hidden;
  }
}