/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  :root {
    --ggl-nav-bg: #323232;
    --ggl-nav-group-bg: #323232;
    --ggl-nav-notification-bg: var(--ggl-item-attention-bg);
    --ggl-nav-notification-text: rgba(255, 255, 255, 0.75);
  }
  .container {
    --ggl-nav-container-width: 13.75rem;
    --ggl-nav-width: 13.75rem;
    --ggl-nav-height: 100%;
    --ggl-nav-z-index: 500;
  }
  .container[data-state=collapsed] {
    --ggl-nav-width: 3.5rem;
    --ggl-nav-container-width: 3.5rem;
  }
  .container:not(.suppress):hover {
    --ggl-nav-width: 13.75rem;
  }
  .container,
  .nav {
    transition-delay: 0;
    transition-duration: 250ms;
    transition-property: width;
    transition-timing-function: ease-in;
  }
  .container {
    min-width: 3.5rem;
    width: var(--ggl-nav-container-width);
    position: relative;
    height: var(--ggl-nav-height);
    height: -webkit-fill-available;
    flex-shrink: 0;
  }
  .nav {
    container-type: inline-size;
    container-name: navbar;
    height: 100%;
    z-index: var(--ggl-nav-z-index);
    background-color: var(--ggl-nav-group-bg);
    position: fixed;
    padding: 0.5rem;
    color: var(--ggl-nav-notification-text);
    user-select: none;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    width: var(--ggl-nav-width);
  }
  .features,
  .poweredBy {
    display: flex;
    flex-direction: column;
  }
  .poweredBy {
    border-top: solid thin rgba(255, 255, 255, 0.15);
    padding-top: 0.5rem;
    cursor: pointer;
  }
  .features {
    height: 100%;
  }
  .nav-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--ggl-nav-group-bg);
    border-radius: var(--ggl-border-radius-panel);
    cursor: pointer;
  }
  .group-label {
    height: 2.5rem;
    padding-left: 0.75rem;
    color: rgba(255, 255, 255, 0.3);
    text-overflow: ellipsis;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  .nav-item,
  .profile [role=button] {
    height: 2.5rem;
    padding: 0.25rem 0.65rem;
    border-radius: var(--ggl-border-radius-panel);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: rgba(255, 255, 255, 0.75);
    transition-delay: 0;
    transition-duration: 250ms;
    transition-property: background-color;
    transition-timing-function: ease-in-out;
    transition: width 0;
  }
  .nav-item:hover,
  .profile [role=button]:hover {
    background-color: rgba(255, 255, 255, 0.05);
    transition-duration: 50ms;
  }
  .nav-item:active,
  .profile [role=button]:active {
    background-color: rgba(255, 255, 255, 0.25);
    color: rgb(255, 255, 255);
  }
  .nav-item.active,
  .profile [role=button].active {
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .nav-item.active:hover,
  .profile [role=button].active:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
  .nav-item.active:active,
  .profile [role=button].active:active {
    background-color: rgba(255, 255, 255, 0.25);
  }
  .logo {
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
  }
  .logoContainer {
    border-bottom: solid thin rgba(255, 255, 255, 0.15);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .icon {
    flex-grow: 0;
    flex-shrink: 0;
    height: 1.25rem;
    width: 1.25rem;
    fill: rgba(255, 255, 255, 0.75);
  }
  .logout {
    background-color: var(--ggl-nav-group-bg);
    cursor: pointer;
  }
  .logout:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
  .logout:active {
    background-color: rgba(255, 255, 255, 0.25);
  }
  .branding {
    background-color: #323232;
    font-size: 12px;
    border-radius: var(--ggl-border-radius-panel);
    height: 2.5rem;
    display: flex;
    align-items: center;
    padding: 0 0.25rem;
  }
  .branding span {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
  }
  .toggle {
    background-color: transparent;
    border: none;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .itemLabel {
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
    word-wrap: none;
    white-space: nowrap;
  }
  .notification {
    background-color: var(--ggl-nav-notification-bg);
    padding: 0.125rem;
    font-size: 12px;
    border-radius: var(--ggl-border-radius-input);
    line-height: 1rem;
    padding-bottom: 0.1875rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    min-width: 1.25rem;
    text-align: center;
    margin-right: 0.25rem;
  }
  .logoSmall {
    display: none;
  }
  .poweredItem {
    height: 1.5rem;
    fill: #ccc;
  }
  .profile {
    margin-top: auto;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }
  .avatar {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: -0.125rem;
  }
  .contextMenu {
    transition: none;
  }
  .contextMenu > section {
    background-color: var(--ggl-nav-group-bg);
  }
  .contextMenu .li {
    display: flex !important;
  }
}
@container navbar (max-width: 180px) {
  .notification {
    display: none;
  }
  .group-label {
    visibility: hidden;
  }
}
@container navbar (max-width: 150px) {
  .logoLarge {
    display: none;
  }
}
@container navbar (max-width: 57px) {
  .logoLarge,
  .profile .icon {
    display: none;
  }
  .logoSmall {
    display: block;
  }
  .notification {
    display: block;
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    color: transparent;
    padding: 0;
    position: relative;
    top: -0.5rem;
    left: -1.5rem;
    min-width: 1rem;
  }
}
@media (max-width: 960px) {
  .container {
    --ggl-nav-width: 3.5rem;
    --ggl-nav-container-width: 3.5rem;
  }
  .container:hover {
    --ggl-nav-width: 13.75rem;
  }
}
@media (max-width: 480px) {
  .container[data-state] {
    --ggl-nav-width: calc(100% - 4.5rem);
    --ggl-nav-container-width: calc(100% - 4.5rem);
    min-width: 17.5rem;
    max-width: 28.75rem;
    transition: 150ms ease-out;
    position: absolute;
    top: 0;
  }
  .container[data-state=collapsed] {
    left: calc((100% - 4.5rem) * -1);
  }
  .container[data-state=expanded] {
    left: 0;
    box-shadow: var(--ggl-drop-shadow-high);
  }
  .branding span {
    justify-content: center;
    gap: 1rem;
  }
}