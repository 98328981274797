$brand: "ggl";
.scroll {
  height: 100%;
  width: 100%;
  position: relative;
  padding-left: 3.5rem;
  padding-top: 3.5rem;
  float: left;
}

.hasBreadcrumb {
  padding-top: calc(3rem + 3rem);
}

.navOpen {
  padding-left: 16rem;
}

.main {
  display: flex;
  width: inherit;
  gap: 1rem;
  position: relative;
  clear: both;
  float: left;
  min-height: 100%;
}

.bannerContainer {
  position: fixed;
  z-index: var(--banner-z-index);
  width: 100%;
  top: 2rem;
}

.banner {
  transition: 500ms;
  width: 37.5rem;
  opacity: 0.95;
  border-radius: 0.5rem;
  margin-left: auto;
  margin-right: auto;

  a {
    --ggl-link-color: --ggl-msg-primary-text;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 860px) {
  .scroll {
    padding-left: 0;
  }
  .bannerContainer {
    top: var(--header-height);
  }
  .banner {
    width: 100vw;
    max-width: 100%;
    border-radius: 0rem;
    margin: 0;
  }

  .hasBreadcrumb {
    padding-top: calc(3rem + 2.5rem);
  }
}

@media print {
  .scroll {
    padding-left: 0rem;
    padding-top: 2.5rem;
  }
}
