$brand: "ggl";
.btnRow {
  width: 50vw;
  min-width: 18rem;
  max-width: 40rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
}

.btnRowSingle {
  width: 50vw;
  min-width: 18rem;
  max-width: 40rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 1rem;
}

.newPasswordInput {
  margin-top: 1rem;
}

.inlineIcon {
  vertical-align: text-bottom;
  width: 1.2rem;
  height: 1.2rem;
  margin-top: -0.05rem;
  margin-bottom: -0.1rem;
}

.bigIcon {
  width: 3.5rem;
  height: 3.5rem;
}

.bad {
  fill: var(--ggl-text-destructive-accent);
}
.good {
  fill: var(--ggl-text-success-accent);
}

.restrictionsRow {
  margin-bottom: 0.25rem;
}
