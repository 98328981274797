/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .tabsContainer {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 100%;
    word-break: break-all;
  }
  .headersContainer {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
    grid-column-gap: 0.5rem;
    grid-template-rows: 2rem;
    justify-content: center;
    width: 100%;
  }
  .tabHeader,
  .tabHeaderActive,
  .tabHeaderDisabled {
    position: relative;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: thin solid var(--ggl-control-button-hover);
    margin: 0.5rem 0;
  }
  .tabHeader:last-child,
  .tabHeaderActive:last-child,
  .tabHeaderDisabled:last-child {
    border-right: none;
  }
  .tabHeaderActive .icon,
  .tabHeader .icon {
    fill: var(--ggl-brand);
  }
  .tabHeader > a,
  .tabHeaderActive > a,
  .tabHeaderDisabled > a {
    font-size: 0.75rem;
  }
  .tabHeaderActive > a {
    cursor: default;
  }
  .tabHeaderDisabled > a {
    cursor: default;
    opacity: 0.25;
  }
  .tabHeaderWrapper,
  .tabHeaderWrapperActive,
  .tabHeaderWrapperDisabled {
    display: flex;
    justify-content: center;
    border-top-left-radius: var(--ggl-border-radius-panel);
    border-top-right-radius: var(--ggl-border-radius-panel);
    border-top: thin solid var(--ggl-item-disabled-border);
    border-left: thin solid var(--ggl-item-disabled-border);
    border-right: thin solid var(--ggl-item-disabled-border);
    background-color: var(--ggl-control-button-hover);
  }
  .tabHeaderWrapper {
    cursor: pointer;
    box-shadow: inset 0px -3px 6px 0px rgba(0, 0, 0, 0.1);
  }
  .tabHeaderWrapperActive {
    background-color: var(--ggl-control-bg);
  }
  .tabHeaderWrapperDisabled {
    color: var(--ggl-text-disabled);
    border-color: var(--ggl-border-disabled);
    background-color: var(--ggl-disabled-bg);
    cursor: default;
    box-shadow: inset 0px -3px 6px 0px rgba(0, 0, 0, 0.1);
  }
  .contentContainer {
    flex-grow: 1;
    text-align: center;
    margin-top: 2.5rem;
  }
  .icon {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.5rem;
  }
}