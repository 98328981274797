@layer components {
  .root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
  }
  .box {
    position: absolute;
    left: 50%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .img {
    width: 100%;
    opacity: 0.75;
    fill: rgba(0, 0, 0, 0.6);
  }
  .fill {
    width: 100%;
    opacity: 0.75;
    background-color: rgba(0, 0, 0, 0.6);
  }
}