$brand: "ggl";
.card {
  margin-top: 4rem;
  padding: 2rem !important;
  box-shadow: var(--ggl-drop-shadow-high);
  border-radius: var(--ggl-border-radius-panel);
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.cardCol {
  display: flex;
  column-gap: 1rem;
}
.mcIcon {
  width: 7rem;
}
.mcCode {
  width: 13rem;
}
.mcClock {
  width: 7rem;
}

.helpText {
  text-align: left;
  > * {
    font-size: 12px;
    margin-bottom: 0.5rem;
  }
}

.smallCard {
  width: 15rem;
  box-shadow: var(--ggl-drop-shadow-high);
  border-radius: var(--ggl-border-radius-panel);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem 10rem 1fr;
  row-gap: 1rem;
  justify-items: center;
  align-items: center;
  padding: 1rem;
}

.btnRow {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
}

.smallCardMobile {
  width: 18rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem 10rem 5rem 1rem 7rem;
  row-gap: 1rem;
  background: var(--ggl-panel-bg);
  box-shadow: var(--ggl-drop-shadow-high);
  border-radius: var(--ggl-border-radius-panel);
  padding: 1rem;
  justify-items: center;
  align-items: center;
}

.steps {
  display: flex;
  column-gap: 0.25rem;
}

.dot {
  height: 0.625rem;
  width: 0.625rem;
  border: 0.16rem solid var(--ggl-border-disabled);
  border-radius: 50%;
  display: inline-block;
}

.dotFilled {
  background: var(--ggl-link-color);
}
