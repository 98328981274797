/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .icon,
  .button {
    margin-left: 0.25rem;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    fill: var(--ggl-text-color);
    cursor: pointer;
  }
  .button.hover, .button:hover, .button.active, .button:active, .button {
    color: var(--ggl-text-color);
    background-color: transparent;
  }
  .button {
    padding: 0.125rem 0 0 0.2rem;
    border: none;
    margin: 0.3125rem;
    height: 1.25rem;
    width: 1.375rem;
    outline: none;
  }
  .button.hover, .button:hover {
    cursor: pointer;
  }
  .button .icon {
    margin: 0;
    fill: var(--ggl-text);
    height: 100%;
    width: 100%;
  }
  .hint {
    margin-left: 0.125rem;
    height: 1.5rem;
    width: 1.5rem;
    position: relative;
    position: relative;
  }
  .hint .icon {
    fill: var(--ggl-link-color);
  }
  [disabled],
  .disabled {
    cursor: initial;
  }
  [disabled] *,
  .disabled * {
    color: var(--ggl-text-disabled);
  }
  [disabled] .icon,
  .disabled .icon {
    fill: var(--ggl-text-disabled);
    background-color: transparent;
  }
  [disabled]:hover,
  .disabled:hover {
    background-color: transparent;
  }
  .skeleton {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
  }
  .skeleton :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .skeleton * {
    visibility: hidden !important;
  }
  .skeleton::after {
    display: none;
  }
}