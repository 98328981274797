/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root,
  .root:hover {
    border: solid thin var(--ggl-control-checkbox-border);
    border-radius: var(--ggl-border-radius-checkbox);
    box-shadow: var(--ggl-control-checkbox-box-shadow);
    width: 1.125rem;
    height: 1.125rem;
    box-sizing: border-box;
  }
  .root svg,
  .root:hover svg {
    fill: var(--ggl-control-bg);
    width: 1.125rem;
    height: 1.125rem;
  }
  .root:not(.expanded) {
    box-shadow: none;
    background-color: var(--ggl-control-collapsed);
  }
  .root:not(.expanded) > span {
    top: -1px;
  }
  .expanded {
    background-color: transparent;
    fill: var(--ggl-control-collapsed);
  }
  .expanded:hover {
    background-color: transparent;
  }
  .expanded svg,
  .expanded:hover svg {
    fill: var(--ggl-control-collapsed);
  }
}