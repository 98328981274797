@layer components {
  .menu {
    position: absolute;
    z-index: 1;
    min-width: 3rem;
    min-height: 3rem;
    padding: 0;
  }
  .root {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  .scroll {
    overflow-y: scroll;
  }
  .actionSheet {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    margin: auto;
  }
  .actionSheet:has(.scroll) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .actionSheet::backdrop {
    background-color: red;
  }
  .actionSheet .scroll {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: calc(100vh - 4rem);
  }
  .actionSheet .menu {
    padding: 0.5rem;
    min-width: 15rem;
    max-width: calc(100vw - 2rem);
  }
}