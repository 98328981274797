/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    min-height: var(--ggl-control-item-height);
    height: fit-content;
    background-color: var(--ggl-control-bg);
    border-radius: 0.25rem;
    border: thin solid var(--ggl-control-border-light);
    display: flex;
    align-items: stretch;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    color: var(--ggl-text-color-accent);
    width: 100%;
    font-size: 1em;
  }
  .root:focus-within {
    border-width: 0.05rem;
    border-color: var(--ggl-brand);
  }
  .root:focus-within > button {
    background-color: transparent;
    visibility: visible;
  }
  .root:focus-within > button:active {
    background-color: transparent;
  }
  .root.error {
    border-color: var(--ggl-control-error);
    border-width: 0.1rem;
  }
  .root.error:focus-within::after {
    border-color: var(--ggl-control-error);
  }
  .btn {
    font-size: 1rem;
    height: 2em;
    width: 2em;
    margin-left: 0.25rem;
  }
  .btn span {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon {
    fill: var(--ggl-text-primary-color);
  }
  .btnWrapper {
    display: flex;
    align-items: center;
    height: calc(var(--ggl-control-item-height) - 2px);
  }
  .btnWrapper button {
    height: 2rem;
    width: 2rem;
  }
  .loading_icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  .default {
    padding: 0.5rem;
    height: 3rem;
  }
  @media only screen and (min-width: 860px) {
    .default {
      padding-left: 0.25rem !important;
      padding: 0rem;
      height: var(--ggl-control-item-height);
    }
  }
  .medium {
    padding-left: 0.25rem !important;
    padding: 0rem;
    height: var(--ggl-control-item-height);
  }
  .large {
    padding: 0.5rem;
    height: 3rem;
  }
}