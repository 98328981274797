/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    border-radius: 0.25rem;
    border: none;
    font-size: 0.5rem;
    height: 2rem;
    width: 2rem;
    margin: 0;
    padding: 0.25rem;
    font-size: 100%;
    background-color: transparent;
    cursor: pointer;
  }
  .root:hover {
    background-color: var(--ggl-control-button-hover);
  }
  .root svg {
    fill: var(--ggl-control-button-active);
  }
  .root svg:hover {
    fill: var(--ggl-control-button-collapsed);
  }
}