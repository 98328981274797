/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    height: auto;
    overflow: hidden;
  }
  .banner {
    box-shadow: var(--ggl-drop-shadow-low);
    color: var(--ggl-item-primary-text);
    background-color: var(--ggl-item-primary-bg);
    display: grid;
    grid-template-columns: 3.5rem 1fr 3.5rem;
    grid-template-rows: auto;
    grid-template-areas: "icon message close";
    width: 100%;
    color: var(--ggl-item-primary-text);
    background-color: var(--ggl-item-primary-bg);
    min-height: 3.5rem;
  }
  .banner svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--ggl-item-primary-text) !important;
  }
  .fadeIn {
    animation: slideDown 0.5s ease-in;
  }
  .fadeOut {
    animation: slideUp 0.5s ease-out;
  }
  .attention {
    color: var(--ggl-item-attention-text);
    background-color: var(--ggl-item-attention-bg);
  }
  .attention svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--ggl-item-attention-text) !important;
  }
  .success {
    color: var(--ggl-item-success-text);
    background-color: var(--ggl-item-success-bg);
  }
  .success svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--ggl-item-success-text) !important;
  }
  .destructive {
    color: var(--ggl-item-destructive-text);
    background-color: var(--ggl-item-destructive-bg);
  }
  .destructive svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--ggl-item-destructive-text) !important;
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: "icon";
  }
  .closeBtn {
    height: 2rem;
    width: 2rem;
  }
  .closeBtn:hover {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: "message";
    justify-content: left;
    line-height: 1.125rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: "close";
  }
}
@keyframes slideDown {
  0% {
    margin-top: -3.5rem;
  }
  100% {
    margin-bottom: 0;
  }
}
@keyframes slideUp {
  0% {
    margin-bottom: 0;
  }
  100% {
    margin-top: -3.5rem;
  }
}