/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .appBar {
    display: flex;
    height: 3.25rem;
    gap: 0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    background-color: var(--ggl-nav-group-bg);
    min-width: 20rem;
    align-items: center;
  }
  .icon {
    height: 1.5rem;
    width: 1.5rem;
    fill: rgba(255, 255, 255, 0.75);
  }
  .bell {
    height: 2rem;
    width: 2rem;
    fill: rgba(255, 255, 255, 0.75);
  }
  .item,
  .logoBox {
    background-color: var(--ggl-nav-group-bg);
    border-radius: var(--ggl-border-radius-panel);
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 3.25rem;
    overflow: hidden;
    cursor: pointer;
  }
  .logoBox {
    width: auto;
    flex-grow: 1;
    padding: 0.75rem;
  }
  .notification {
    background-color: var(--ggl-nav-notification-bg);
    padding: 0.125rem;
    font-size: 12px;
    border-radius: 50%;
    line-height: 1rem;
    padding-bottom: 0.1875rem;
    color: var(--ggl-nav-notification-text);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    min-width: 1.25rem;
    text-align: center;
    margin-right: 0.25rem;
    position: absolute;
    right: 1.35rem;
    top: 1.5rem;
  }
}