/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    display: block;
  }
  .rows-1 {
    height: 4.75rem;
  }
  .rows-1 .fake_input {
    height: 2rem;
  }
  .rows-2 {
    height: 10.25rem;
  }
  .rows-2 .fake_input {
    height: 7.5rem;
  }
  .rows-3 {
    height: 15.75rem;
  }
  .rows-3 .fake_input {
    height: 13rem;
  }
  .rows-4 {
    height: 21.25rem;
  }
  .rows-4 .fake_input {
    height: 18.5rem;
  }
  .label {
    margin-bottom: 0.25rem;
  }
  .fake_input {
    min-height: var(--ggl-control-item-height);
    height: fit-content;
    background-color: var(--ggl-control-bg);
    border-radius: 0.25rem;
    border: thin solid var(--ggl-control-border-light);
    display: flex;
    align-items: stretch;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    color: var(--ggl-text-color-accent);
    width: 100%;
  }
  .fake_input:focus-within {
    border-width: 0.05rem;
    border-color: var(--ggl-brand);
  }
  .fake_input:focus-within > button {
    background-color: transparent;
    visibility: visible;
  }
  .fake_input:focus-within > button:active {
    background-color: transparent;
  }
  .fake_input.error {
    border-color: var(--ggl-control-error);
    border-width: 0.1rem;
  }
  .fake_input.error:focus-within::after {
    border-color: var(--ggl-control-error);
  }
  .readonly {
    border-color: var(--ggl-border-readonly);
  }
  .readonly:focus-within {
    border-color: var(--ggl-border-readonly-focus);
  }
  .primary {
    color: var(--ggl-item-primary-text);
    border-color: var(--ggl-item-primary-border);
    border-bottom-color: var(--ggl-item-primary-border-tint);
    background-color: var(--ggl-item-primary-bg);
  }
  .primary svg {
    fill: var(--ggl-item-primary-text);
  }
  .primary:hover {
    color: var(--ggl-item-primary-hover-text);
    border-color: var(--ggl-item-primary-hover-border);
    border-bottom-color: var(--ggl-item-primary-hover-border-tint);
    background-color: var(--ggl-item-primary-hover-bg);
  }
  .primary:hover svg {
    fill: var(--ggl-item-primary-hover-text);
  }
  .primary:active, .primary.active, .primary.loading {
    color: var(--ggl-item-primary-active-text);
    border-color: var(--ggl-item-primary-active-border);
    border-top-color: var(--ggl-item-primary-active-border-tint);
    background-color: var(--ggl-item-primary-active-bg);
  }
  .primary:active svg, .primary.active svg, .primary.loading svg {
    fill: var(--ggl-item-primary-active-text);
  }
  .secondary {
    color: var(--ggl-item-secondary-text);
    border-color: var(--ggl-item-secondary-border);
    border-bottom-color: var(--ggl-item-secondary-border-tint);
    background-color: var(--ggl-item-secondary-bg);
  }
  .secondary svg {
    fill: var(--ggl-item-secondary-text);
  }
  .secondary:hover {
    color: var(--ggl-item-secondary-hover-text);
    border-color: var(--ggl-item-secondary-hover-border);
    border-bottom-color: var(--ggl-item-secondary-hover-border-tint);
    background-color: var(--ggl-item-secondary-hover-bg);
  }
  .secondary:hover svg {
    fill: var(--ggl-item-secondary-hover-text);
  }
  .secondary:active, .secondary.active, .secondary.loading {
    color: var(--ggl-item-secondary-active-text);
    border-color: var(--ggl-item-secondary-active-border);
    border-top-color: var(--ggl-item-secondary-active-border-tint);
    background-color: var(--ggl-item-secondary-active-bg);
  }
  .secondary:active svg, .secondary.active svg, .secondary.loading svg {
    fill: var(--ggl-item-secondary-active-text);
  }
  .attention {
    color: var(--ggl-item-attention-text);
    border-color: var(--ggl-item-attention-border);
    border-bottom-color: var(--ggl-item-attention-border-tint);
    background-color: var(--ggl-item-attention-bg);
  }
  .attention svg {
    fill: var(--ggl-item-attention-text);
  }
  .attention:hover {
    color: var(--ggl-item-attention-hover-text);
    border-color: var(--ggl-item-attention-hover-border);
    border-bottom-color: var(--ggl-item-attention-hover-border-tint);
    background-color: var(--ggl-item-attention-hover-bg);
  }
  .attention:hover svg {
    fill: var(--ggl-item-attention-hover-text);
  }
  .attention:active, .attention.active, .attention.loading {
    color: var(--ggl-item-attention-active-text);
    border-color: var(--ggl-item-attention-active-border);
    border-top-color: var(--ggl-item-attention-active-border-tint);
    background-color: var(--ggl-item-attention-active-bg);
  }
  .attention:active svg, .attention.active svg, .attention.loading svg {
    fill: var(--ggl-item-attention-active-text);
  }
  .destructive {
    color: var(--ggl-item-destructive-text);
    border-color: var(--ggl-item-destructive-border);
    border-bottom-color: var(--ggl-item-destructive-border);
    background-color: var(--ggl-item-destructive-bg);
  }
  .destructive svg {
    fill: var(--ggl-item-destructive-text);
  }
  .destructive:hover {
    color: var(--ggl-item-destructive-hover-text);
    border-color: var(--ggl-item-destructive-hover-border);
    border-bottom-color: var(--ggl-item-destructive-hover-border);
    background-color: var(--ggl-item-destructive-hover-bg);
  }
  .destructive:hover svg {
    fill: var(--ggl-item-destructive-hover-text);
  }
  .destructive:active, .destructive.active, .destructive.loading {
    color: var(--ggl-item-destructive-active-text);
    border-color: var(--ggl-item-destructive-active-border);
    border-top-color: var(--ggl-item-destructive-active-border);
    background-color: var(--ggl-item-destructive-active-bg);
  }
  .destructive:active svg, .destructive.active svg, .destructive.loading svg {
    fill: var(--ggl-item-destructive-active-text);
  }
  .skeleton {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
    width: 100%;
  }
  .skeleton :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .skeleton * {
    visibility: hidden !important;
  }
  .skeleton::after {
    display: none;
  }
  .skeleton input {
    visibility: hidden;
  }
  .input {
    background-color: transparent;
    border: none;
    outline: transparent;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .active {
    border-radius: 0.25rem;
    border-color: var(--ggl-brand);
  }
  input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input::-webkit-date-and-time-value {
    text-align: left;
  }
  .behind {
    position: absolute;
    right: 0;
  }
  input + .icon {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  .prefix {
    background-color: var(--ggl-disabled-bg);
    color: var(--ggl-text-disabled);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 100%;
    align-items: center;
    display: flex;
  }
  .prefix_span {
    padding-bottom: 1px;
  }
  /* disabled */
  .disabled .label {
    color: var(--ggl-text-disabled);
  }
  .disabled .fake_input {
    color: var(--ggl-text-disabled);
    border-color: var(--ggl-border-disabled);
    background-color: var(--ggl-disabled-bg);
  }
  .select {
    flex-basis: 100%;
    flex-grow: 1;
    border: none;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    outline: none;
    background-color: transparent;
    position: relative;
    z-index: 2;
  }
  .select::-ms-expand {
    display: none;
  }
  button[aria-label=clear] {
    font-size: 0.7rem;
    margin-right: 0.25rem;
  }
  .large {
    height: 2.375rem !important;
  }
  .large div {
    height: 2.3rem;
  }
  .large button[aria-label=clear] {
    font-size: 1em;
    margin-right: 0.25rem;
  }
}