@layer components {
  :root {
    --dropdown-context-menu-gap: 0.25rem;
  }
  .menu {
    min-width: 3rem;
    min-height: 3rem;
    padding: 0rem !important;
    border-radius: var(--ggl-border-radius-panel);
    border: solid 0.05rem var(--ggl-text-primary-disabled);
    height: calc(100% - var(--dropdown-context-menu-gap));
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .dialog {
    position: fixed;
    border: none;
    top: 0;
    right: 0;
    z-index: 1;
    margin: 0;
    padding: 0.35rem 0.25rem 0.35rem 0.25rem;
    margin-left: -0.3rem;
    overflow: hidden;
    background: transparent;
  }
  .dialog::backdrop {
    background-color: transparent;
  }
  .dialog:focus-visible {
    border: none;
    outline: none;
  }
  .actionSheet {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    margin: auto;
  }
  .actionSheet::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .actionSheet .scroll {
    height: fit-content;
    max-height: calc(100vh - 4rem);
  }
  .actionSheet .menu {
    padding: 0.5rem;
    min-width: 15rem;
    max-width: calc(100vw - 2rem);
  }
}