/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    display: flex;
    width: auto;
    gap: 0.5rem;
  }
  .control {
    min-height: var(--ggl-control-item-height);
    height: fit-content;
    background-color: var(--ggl-control-bg);
    border-radius: 0.25rem;
    border: thin solid var(--ggl-control-border-light);
    display: flex;
    align-items: stretch;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    color: var(--ggl-text-color-accent);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.5rem;
  }
  .control:focus-within {
    border-width: 0.05rem;
    border-color: var(--ggl-brand);
  }
  .control:focus-within > button {
    background-color: transparent;
    visibility: visible;
  }
  .control:focus-within > button:active {
    background-color: transparent;
  }
  .control.error {
    border-color: var(--ggl-control-error);
    border-width: 0.1rem;
  }
  .control.error:focus-within::after {
    border-color: var(--ggl-control-error);
  }
  .control.hasError {
    border-color: var(--ggl-control-error);
    border-width: 0.1rem;
  }
  .control.hasError:focus-within::after {
    border-color: var(--ggl-control-error);
  }
  .disabled .control {
    color: var(--ggl-text-disabled);
    border-color: var(--ggl-border-disabled);
    background-color: var(--ggl-disabled-bg);
  }
  .compact .root {
    flex-direction: column;
  }
  .skeleton {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
  }
  .skeleton :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .skeleton * {
    visibility: hidden !important;
  }
  .skeleton::after {
    display: none;
  }
  .container {
    container-type: inline-size;
    container-name: datarange;
  }
  @container datarange (width < 480px) {
    .root {
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 1rem;
    }
  }
}