.content > aside > div {
  font-size: 0.875rem;
  background:  var(--ggl-control-bg);
  line-height: 1.2rem;
}
.icon {
  height: 2rem;
  width: 2rem;
  justify-content: flex-start;
  flex: 0 0 auto;
  align-self: center;
}
.icon linearGradient :nth-child(1) {
  stop-color: #FF8400;
}

.icon linearGradient :nth-child(2) {
  stop-color: #FF5C00;
}


.button {
  width: 5rem;
}
.subscription {
  border-radius: 0.25rem;
  display: flex;
  border: 1px solid var(--ggl-control-checkbox-readonly-border);
  background-color: var(--ggl-control-light-bg);
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  gap: 0.5rem;
  margin: 0.75rem 0;
}
.subscriptionName{
  color: var(--ggl-text-faint);
  font-weight: 400;
}
.expiryDate{
  color: var(--ggl-label);
  font-weight: 600;
}
