@layer components {
  .iconBtn {
    padding: 0;
    width: 2rem;
    height: 2rem;
    aspect-ratio: 1/1;
    padding: 0;
    overflow: hidden;
    min-width: 0;
  }
  .iconBtn.children {
    color: transparent;
    display: none;
  }
  .iconBtn .container {
    font-size: 1.25rem;
  }
}