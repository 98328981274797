@layer components {
  .dialog {
    margin: 0;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    z-index: 999;
    padding: 0.25rem 1.5rem;
  }
  .dialog::backdrop {
    background-color: transparent;
  }
  .message {
    padding: 0.5em;
    background-color: var(--ggl-text-accent);
    border-radius: var(--ggl-border-radius-input);
    box-shadow: var(--ggl-drop-shadow-high);
    max-width: 15rem;
    white-space: break-spaces;
    user-select: none;
    font-size: 0.75rem;
    color: var(--ggl-item-primary-text);
    font-weight: 600;
    line-height: 1rem;
    align-items: center;
  }
  .arrow {
    width: 1.25rem;
    height: 1.125rem;
    fill: var(--ggl-text-accent) !important;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.4));
  }
  .above {
    flex-direction: column;
    margin-top: -0.5rem;
  }
  .above .arrow {
    transform: rotate(0deg);
  }
  .below {
    flex-direction: column-reverse;
    margin-top: 0.5rem;
  }
  .below .arrow {
    transform: rotate(180deg);
  }
  .right {
    flex-direction: row-reverse;
    margin-left: 0.5rem;
  }
  .right .arrow {
    transform: rotate(90deg);
    margin-right: -0.125rem;
  }
  .left {
    flex-direction: row;
    margin-left: -0.5rem;
  }
  .left .arrow {
    transform: rotate(-90deg);
    margin-left: -0.125rem;
  }
}