$brand: "ggl";
.root {
    background-color: var(--ggl-control-bg);
    border-bottom: thin solid var(--ggl-control-border-light);
    height: var(--header-height);
    width: 100%;
    align-items: center;
    position: fixed;
    overflow: hidden;
    z-index: var(--header-z-index);
    display: grid;
    grid-template-areas: "hamburger back centre autosave feedback space profile";
    grid-template-columns: auto auto 1fr 3.25rem auto 1rem auto;
}

.headerBack {
    grid-area: back;
    border: none;
    text-decoration: none;
}

.banner {
    position: fixed;
    float: left;
    width: 100%;
    z-index: 600;
    transition: 500ms;
    display: none;
    top: 3rem;
}

.profile {
    grid-area: profile;
    align-items: center;
    padding-right: 1.5rem;
    cursor: pointer;
    height: 100%;
    display: flex;
    &:hover {
        background-color: var(--ggl-header-bg-hover);
    }
}

.hamburger {
    grid-area: hamburger;
    width: 3.5rem;
    height: 3.5rem;
}

.active {
    background-color: var(--ggl-header-bg-active);
}

.contextMenu {
    margin-left: auto !important;
    z-index: 100 !important;
    padding-right: 0.5em !important;
}

.menu {
    display: flex;
    border-bottom: solid thin var(--ggl-control-border-light);
    padding-bottom: 0.5rem;
    min-width: 14rem;
    margin: 0.5rem;
}

.details {
    margin-left: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.name {
    color: var(--ggl-text);
    font-size: 1.125rem;
}

.email {
    margin-top: 0.25rem;
    line-height: 1rem;
    font-size: 0.75rem;
}
.version {
    font-size: small;
    color: var(--ggl-text-primary-disabled);
    width: 100%;
    padding-left: 1rem;
    margin-bottom: 0.5rem;
}

.earlyAccess {
    color: var(--ggl-text-faint);
    flex-grow: 100;
    text-align: center;
    font-size: 0.75rem;
    line-height: normal;
}

.feedbackBtn {
    grid-area: feedback;
}

.autosaveIndicatorPortal {
    grid-area: autosave;
}

@media only screen and (min-width: 860px) {
    .headerBack {
        margin-left: 0.825rem;
    }
}

@media only screen and (max-width: 480px) {
    .feedbackBtn {
        display: none;
    }
}

@media only screen and (max-width: 860px) {
    .root {
        width: 100%;
        margin-left: 0;
    }
    .logo {
        flex-grow: 1;
        justify-content: center;
    }
    .title {
        display: none;
    }
    .banner {
        display: block;
    }

    .earlyAccess {
        padding-right: 0.85rem;
    }
}

@media print {
    .profile,
    .title {
        display: none;
    }
    .root {
        background-color: var(--ggl-control-bg);
    }
}
