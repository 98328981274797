@layer components {
  .container {
    padding: 0.5rem 1rem 1rem 1rem;
    width: 100%;
  }
  .readonly section {
    border-color: var(--ggl-border-readonly);
  }
  .readonly section:focus-within {
    border-color: var(--ggl-border-readonly-focus);
  }
}