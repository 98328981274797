/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .panel {
    --ggl-panel-min-width: 18rem;
    --ggl-panel-width: 40.625rem;
    --ggl-panel-max-width: 62.5rem;
    --ggl-panel-height: fit-content;
    border-radius: var(--ggl-border-radius-panel);
    min-width: var(--ggl-panel-min-width);
    max-width: var(--ggl-panel-max-width);
    width: var(--ggl-panel-width);
    height: var(--ggl-panel-height);
    background-color: var(--ggl-form-bg);
    border: var(--ggl-panel-border) thin solid;
    box-shadow: var(--ggl-drop-shadow-low);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .panel .content {
    height: var(--ggl-panel-height);
    overflow-y: auto;
    flex-grow: 1;
  }
  .panel .content.padded {
    padding: 0.75rem;
  }
  .panel.large {
    --ggl-panel-min-width: 36rem;
    --ggl-panel-width: 81.25rem;
    --ggl-panel-max-width: 93.75rem;
  }
  .panel.small {
    --ggl-panel-width: 20rem;
    --ggl-panel-max-width: 30rem;
  }
  .panel.tiny {
    --ggl-panel-width: 12rem;
    --ggl-panel-max-width: 20rem;
  }
  .panel.fullWidth {
    --ggl-panel-width: 100%;
    --ggl-panel-max-width: 100%;
  }
  .panel.fullHeight {
    height: 100%;
  }
  .panel.fullHeight.topBottom {
    --ggl-panel-height: calc(100% - 7rem);
  }
  .panel.fullHeight.top {
    --ggl-panel-height: calc(100% - 3.5rem);
  }
  .panel .header,
  .panel .footer {
    height: 3.5rem;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-shrink: 0;
  }
  .panel .header {
    border-bottom: var(--ggl-panel-border) thin solid;
  }
  .panel .title {
    margin-bottom: 0.4rem;
  }
  .panel .back {
    padding-right: 0.5rem;
    margin-right: 0.5rem;
    border-right: solid thin var(--ggl-panel-border);
    margin-left: -0.25rem;
  }
  .panel .footer {
    justify-content: space-between;
    border-top: var(--ggl-panel-border) thin solid;
    width: 100%;
  }
  .panel .dismiss {
    margin-left: auto;
  }
  .panel .headerControls {
    margin-left: auto;
  }
  .panel .controls {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    background-color: rgba(0, 0, 0, 0.025);
    border-bottom: var(--ggl-panel-border) thin solid;
    flex-shrink: 0;
  }
}