@layer components {
  .panel {
    display: flex;
    justify-content: center;
    width: fit-content;
    padding-top: 5rem;
    margin: 1rem auto 1rem auto;
  }
  .body {
    background-color: var(--ggl-body-bg);
    padding: 2rem 4rem 2rem 4rem;
    width: fit-content;
    border-radius: 1rem;
    color: var(--ggl-text-color);
    align-items: center;
  }
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
  }
  .hint {
    text-align: center;
    width: 100%;
    display: block;
  }
  .icon {
    fill: var(--ggl-text);
    width: 5rem;
    height: auto;
  }
  @media only screen and (max-width: 860px) {
    .panel {
      display: flex;
      justify-content: center;
      padding-top: 5rem;
    }
    .body {
      padding: 1rem 2rem 1rem 2rem;
    }
    .title {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    .hint {
      font-size: 0.8rem;
      text-align: center;
      width: 100%;
      display: block;
      white-space: pre-line;
    }
  }
  @media only screen and (max-width: 480px) {
    .panel {
      margin: 1rem;
      width: calc(100% - 2rem);
    }
  }
}