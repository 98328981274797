@layer components {
  .root {
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    width: 100%;
  }
  .root.panel {
    flex-direction: row;
    justify-content: space-between;
  }
  .root button {
    min-width: 6rem;
  }
  .error {
    margin-bottom: 0 !important;
    margin-right: auto;
  }
  .centered {
    justify-content: center;
  }
  @media only screen and (max-width: 480px) {
    .root {
      gap: 0.5rem;
    }
    .root button {
      height: 2.5rem;
      width: auto;
      min-width: 5rem;
    }
  }
}