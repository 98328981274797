@layer components {
  .wrapper {
    height: 1.5rem;
    display: flex;
    align-items: center;
  }
  .toggleTextEnabled {
    line-height: 1.5rem;
    padding-left: 8px;
    color: var(--ggl-item-primary-bg);
  }
  .toggleTextDisabled {
    line-height: 1.5rem;
    padding-left: 8px;
    color: var(--ggl-text-faint);
  }
  .switch {
    position: relative;
    width: 2.5rem;
    height: 1.5rem;
    top: 0.063rem;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    border-radius: 3.125rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--ggl-control-border);
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 1.25rem;
    width: 1.25rem;
    left: 0.125rem;
    top: 0.125rem;
    border-radius: 100%;
    background-color: var(--ggl-control-bg);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    transition: 0.4s;
  }
  input:checked + .slider:before {
    transform: translateX(100%);
    left: -0.125rem;
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
    background-color: var(--ggl-control-bg);
  }
  input:checked + .slider {
    background-color: var(--ggl-item-primary-bg);
  }
  input[disabled] + .slider {
    cursor: default;
    background-color: var(--ggl-control-border-light);
  }
  input[disabled] + .slider:before {
    box-shadow: none;
  }
}