/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .root {
    height: 1.25rem;
    font-weight: 600;
    color: var(--ggl-label);
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }
  .required {
    color: var(--ggl-control-border-error);
    padding-left: 0.25rem;
  }
  .skeleton {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
    height: 1rem;
    width: 5rem;
  }
  .skeleton :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .skeleton * {
    visibility: hidden !important;
  }
  .skeleton::after {
    display: none;
  }
}