.chip:first-child {
  margin-top: 0;
}

.card{
  font-size: var(--ggl-font-size);
}

.list {
  list-style: disc;
  padding-left: 2rem;
}

.chip {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.moreInformation {
  margin-top: 0.5rem;
}
