@layer components {
  .table {
    width: 100%;
    border-radius: var(--ggl-border-radius-input);
    text-indent: initial;
  }
  .tr {
    height: 3rem;
    font-size: 0.875rem;
  }
  .tr:hover {
    background-color: var(--ggl-control-light-bg);
    color: var(--ggl-text-accent);
    cursor: pointer;
  }
  .cell {
    overflow: hidden;
    text-align: left;
    vertical-align: middle;
    border-bottom: thin solid var(--ggl-control-border-light);
    color: var(--ggl-text);
  }
  .cellHeader {
    background-color: var(--ggl-control-light-bg);
    color: var(--ggl-text-accent);
    pointer-events: none;
    vertical-align: middle;
    overflow: hidden;
    user-select: none;
    text-align: left;
    font-weight: 600;
    padding: 0.75rem 0;
    font-size: 0.875rem;
  }
  .cellHeader:first-child {
    padding-left: 1rem;
  }
  .cellAvatar {
    border-bottom: thin solid var(--ggl-control-border-light);
    width: 2rem;
    user-select: none;
    padding-right: 1rem;
    padding-left: 1rem;
    text-align: left;
    vertical-align: middle;
  }
  @media only screen and (max-width: 860px) {
    .cellAvatar,
    .cellHeader,
    .cell {
      border-bottom: none;
    }
  }
}