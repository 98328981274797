@layer components {
  .root {
    width: 100%;
    height: 100%;
    background-color: var(--ggl-header-bg);
    border: var(--ggl-header-bg);
    padding: 0.85rem;
  }
  .root:hover {
    background-color: var(--ggl-header-bg-hover);
  }
  .root:active {
    background-color: var(--ggl-header-bg-active);
  }
  .root svg {
    fill: var(--ggl-header-text);
    width: 100%;
    height: 100%;
    margin: auto;
  }
  @media only screen and (max-width: 860px) {
    .root {
      background-color: var(--ggl-control-bg);
    }
    .root svg {
      fill: var(--ggl-text);
    }
  }
}