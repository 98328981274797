/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
@layer components {
  .checkButton {
    border: solid thin var(--ggl-control-checkbox-border);
    border-radius: var(--ggl-border-radius-checkbox);
    box-shadow: var(--ggl-control-checkbox-box-shadow);
    height: 1.25rem;
    width: 1.25rem;
    position: relative;
    background-color: transparent;
    margin: 0;
    vertical-align: middle;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .checkButton::before {
    position: relative;
  }
  .checkButton.on, .checkButton.mixed {
    border-color: var(--ggl-item-primary-bg);
    box-shadow: var(--ggl-control-checkbox-box-shadow-checked);
    background-color: var(--ggl-item-primary-bg);
  }
  .checkButton.round {
    border-radius: 50%;
  }
  .checkButton.round::before {
    border-radius: 50%;
  }
  .checkButton .icon,
  .checkButton .icon:hover {
    fill: var(--ggl-item-primary-text) !important;
    height: 1rem;
    width: 1rem;
    transform: scale(1.35);
  }
  .checkButton.readonly {
    border-color: var(--ggl-border-disabled);
  }
  .checkButton.readonly.on, .checkButton.readonly.mixed {
    background-color: var(--ggl-border-disabled);
  }
  .skeleton {
    background-color: var(--ggl-loading-bg) !important;
    border-color: var(--ggl-loading-bg) !important;
    color: transparent !important;
    fill: transparent;
    border-radius: var(--ggl-border-radius-input);
    opacity: 0.25;
    width: 100%;
  }
  .skeleton :global {
    animation: 3s infinite skeleton ease-in-out;
  }
  .skeleton * {
    visibility: hidden !important;
  }
  .skeleton::after {
    display: none;
  }
  .disabled {
    border-color: var(--ggl-border-disabled);
    color: var(--ggl-text-disabled);
  }
}