@use "sass:list";
// import from the component library for now
@use "../../../ggl-components/src/common/styles/variables" as *;
@import "../../../ggl-components/src/common/styles/css-variables";

/* ------------------------------------------------------------------------------
    variable name         |     Light        |         Dark        |
   ------------------------------------------------------------------------------ */
$nav: $N700, $N800;

@mixin webclient_themes($mode) {
  --ggl-nav-bg: #{nth($nav, $mode)};
}

:root {
  @include webclient_themes(1);
  // main header

  --ggl-header-text: #{$N000};
  --ggl-header-bg: #232323;

  --ggl-nav: #{$N300};
  --ggl-nav-active: #{$N600};
  --ggl-nav-hover: #{$N600};
  --ggl-breadcrumb-bar: #323232;

  --header-height: 3.5rem;
  --footer-height: 4.5rem;
  --page-max-width: 80rem;
  --page-border: 1px solid var(--ggl-control-border-light);

  // z-indices
  --nav-z-index: 601;
  --banner-z-index: 600;
  --header-z-index: 499;
  --title-z-index: 498;
  --table-header-z-index: 496;
  --table-body-z-index: 495;
  
  //side panel top header
  --side-panel-header-height: 7rem;
  //assign access tab header
  --assign-access-tab-header-height: 21.5rem;
  //access group list header
  --access-group-list-header-height: 19.5rem;
}

@media only screen and (min-width: 860px) {
  :root {
    --footer-height: 3.5rem;
  }
}

// Comment it for now till it ready to release
// @media (prefers-color-scheme: dark) {
//   :root{
//     @include webclient_themes(2);
//   }
// }
[data-theme="dark"] {
  @include webclient_themes(2);
}
